%navbar-global {
  --_op-navbar-brand-height: calc(12 * var(--op-size-unit)); // 48px
  --_op-navbar-content-item-spacing: var(--op-space-3x-small);
  --_op-navbar-content-spacing: var(--op-space-medium);

  .navbar__brand {
    --_op-navbar-horizontal-spacing: 0;
    display: flex;
    flex-grow: 0;
    align-items: center;

    // Focus State
    &:focus-visible {
      outline: none;
      box-shadow: var(--op-input-focus-primary);
      border-radius: var(--gc-radius-default);
    }

    svg {
      height: 100%;
    }
  }

  .accordion summary .accordion__label {
    font-size: var(--op-font-medium);
  }
}

.navbar-primary, .navbar__links-mobile {
  --_op-navbar-text-color: var(--gc-color-navbar-text);
  --_op-navbar-border-color: var(--gc-color-navbar-background);
  --_op-navbar-background-color: var(--gc-color-navbar-background);
  --op-encoded-images-dropdown-arrow: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9IiNmZmYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02IDguMzc1MDFMMCAyLjM3NTAxTDEuNCAwLjk3NTAwNkw2IDUuNTc1MDFMMTAuNiAwLjk3NTAwNkwxMiAyLjM3NTAxTDYgOC4zNzUwMVoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==');
  --op-encoded-images-dropdown-arrow-hover: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9IiNmZmYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNNiA4LjM3NTAxTDAgMi4zNzUwMUwxLjQgMC45NzUwMDZMNiA1LjU3NTAxTDEwLjYgMC45NzUwMDZMMTIgMi4zNzUwMUw2IDguMzc1MDFaIiBmaWxsPSIjOTFDRDA0Ii8+DQo8L3N2Zz4=');
  padding: var(--op-space-medium) var(--_op-navbar-horizontal-spacing);
}

.navbar__content {
  flex-wrap: nowrap;

  &.navbar__content-main {
    justify-content: start;
    width: 100%;
  }

  .dropdown {
    position: relative;
  }

  .dropdown[open] summary {
    background-color: var(--_op-navbar-background-color);
    color: var(--_op-navbar-text-color);
    box-shadow: inset var(--op-border-all) var(--_op-navbar-background-color);
  }
}

.navbar__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
}

.navbar__left-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--op-space-3x-small);
}

.navbar__right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: var(--op-space-2x-small);
}

.navbar__right-top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--op-space-x-small);
}

.navbar__right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--op-space-x-small);
  align-self: stretch;
}

.menu-navbar .menu-item,
.menu .menu-item {
  font-size: var(--op-font-small);
}


// search_controller applies a .search-visible class to the navbar__links when the search button is clicked
.navbar__links.search-visible {
  opacity: 0;
  pointer-events: none;
}

.navbar-border {
  border-bottom: var(--op-border-width) solid var(--_op-navbar-border-color);
  box-shadow: none;
}

.navbar-bottom {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: var(--op-color-neutral-plus-seven);
  color: var(--op-color-neutral-on-plus-seven);
  border-top: var(--op-border-width) solid var(--_op-navbar-border-color);
}

// Button that toggles the navbar__links open and closed
.mobile-nav-controller {
  display: none;
}

// Items only visible on mobile
.navbar__mobile-only-nav-item {
  display: none;
}


.navbar__links-mobile {
  display: none;
}

@media screen and (max-width: $breakpoint-large) {

  .navbar-primary {
    --_op-navbar-brand-height: calc(9 * var(--op-size-unit)); // 36px
    --_op-navbar-horizontal-spacing: var(--op-space-small);

    .navbar__right-container {
      gap: 0;
      width: 100%;
      justify-content: flex-end;
      min-width: unset;
    }

    .navbar__content {
      flex-wrap: nowrap;

      .form-control {
        --_op-form-control-height-medium: var(--op-input-height-large);
      }

      &.navbar__content-main {
        justify-content: space-between;
      }
    }
  }

  .mobile-nav-controller {
    display: flex;
    flex: 1 1 100%;
    align-self: stretch;
    justify-content: flex-end;
    position: relative;
  }


  .mobile-only-flex {
    display: flex;
  }

  .navbar-primary .navbar__inner {
    justify-content: flex-end;
  }

  // Hides the login button on mobile
  .btn-primary.btn-login {
    display: none;
  }

  .navbar__links-mobile {
    --_op-navbar-horizontal-spacing: var(--op-space-small);
    display: block;
  }

  .navbar__links {
    --op-input-height-large: 5rem;
    --_op-btn-padding-large: 0 var(--_op-navbar-horizontal-spacing);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    transform: translateY(-100%); // move off screen
    transition: var(--gc-transition-menu);
    flex-direction: column;
    background-color: var(--gc-color-navbar-background);
    box-shadow: var(--op-shadow-medium),
      var(--op-border-bottom) var(--op-color-border);
    z-index: -2;
    visibility: hidden;

    &.navbar__links-open {
      transform: translateY(0); // move on screen
      overflow-y: auto;
      max-height: 80vh;
      opacity: 1;
      gap: 0;
      -webkit-overflow-scrolling: touch;
      visibility: visible;
    }

    .btn-navbar,
    .menu-item {
      --op-input-height-large: 5rem;
      --_op-btn-padding-large: 0 var(--_op-navbar-horizontal-spacing);
      width: 100%;
      justify-content: start;
      border-radius: 0;
      padding: var(--_op-btn-padding-large);
      min-height: var(--op-input-height-large);
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }

    .dropdown {
      width: 100%;

      summary.btn-navbar {
        padding: var(--_op-btn-padding-large);
        min-height: var(--op-input-height-large);
        font-size: var(---op-font-medium);
      }
    }

    // Shows the mobile login button on mobile.
    .btn-primary.btn-login {
      display: flex;
    }

    .navbar__mobile-only-nav-item {
      display: flex;
    }
  }

  .navbar__brand {
    flex-grow: 1;
  }

  .navbar__content.dropdown.account-menu {
    display: none;
  }

  .navbar__right-bottom {
    display: none;
  }
}

@media screen and (max-width: $breakpoint-x-small) {
  .navbar-primary {
    --_op-navbar-horizontal-spacing: var(--op-space-small);
  }

  .navbar__mobile-search-form.form-control {
    width: 100%;
  }
}
