%link-hover {
  --gc-link-color: var(--gc-link-hover-color);
  text-decoration-color: transparent;
  text-decoration-skip-ink: none; // Chromium, Firefox
  text-decoration-skip: none; // Safari
}

%link {
  cursor: pointer;
  color: var(--gc-link-color);
  transition: var(--gc-transition-btn-hover);
  font-weight: var(--op-font-weight-medium);
  text-decoration: underline;
  text-decoration-color: currentColor;
  text-decoration-thickness: max(1px, 0.05em);
  text-underline-offset: 1px;
  text-decoration-skip-ink: auto;
  padding: 0;

  &:hover {
    @extend %link-hover
  }

  &.link--primary {
    --gc-link-color: var(--gc-link-primary-color);
  }

  &.link--light {
    --gc-link-color: var(--gc-color-navbar-text);
    --gc-link-hover-color: var(--gc-color-secondary-original);
  }

  &.link--with-icon {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    line-height: var(--op-line-height-dense);
    gap: var(--op-space-3x-small);

    .material-symbols-outlined {
      font-size: 1.1lh;
    }
  }

  &.link--bold {
    font-weight: var(--op-font-weight-bold);
  }

  &.link--medium {
    font-weight: var(--op-font-weight-medium);
  }

  &.link--semi-bold {
    font-weight: var(--op-font-weight-bold);
  }

  &.link--small {
    font-size: var(--op-font-small);
  }

  &.link--navbar {
    font-size: var(--op-font-small);
    line-height: var(--op-line-height-base);

    &:focus-visible {
      background-color: var(--gc-btn-focus-background);
      --gc-link-color: var(--gc-btn-focus-text);
      text-decoration-color: var(--gc-btn-focus-text);
      box-shadow: var(--op-input-focus-primary);
    }

    &:hover {
      background-color: transparent;
      color: var(--gc-color-secondary-original);
      text-decoration-color: var(--gc-color-secondary-original);
      box-shadow: none;
    }
  }

  &.link--active {
    --gc-link-color: var(--gc-color-secondary-original);

    &:focus-visible {
      background-color: var(--gc-btn-focus-background);
      color: var(--gc-btn-focus-text);
      text-decoration-color: var(--gc-btn-focus-text);
      box-shadow: var(--op-input-focus-primary);
    }
  }

  // Focus State
  &:focus-visible {
    outline: none;
    background-color: var(--gc-btn-focus-background);
    color: var(--gc-btn-focus-text);
    box-shadow: var(--op-input-focus-primary);
    border-radius: var(--gc-radius-default);
    text-decoration-color: transparent;
  }
}

.link {
  @extend %link;
}

.link-area {
  width: 100%;

  &:hover {
    ~ .link-area-alternate.link-area {
      .link {
        @extend %link-hover
      }
    }

    .link {
      @extend %link-hover
    }
  }
}
