.notification-preview {
  --notification-badge-size: calc(var(--op-size-unit) * 2.5); // 10px
  display: flex;
  gap: var(--op-space-x-small);
}

.unread-badge {
  display: flex;
  align-items: flex-start;
  gap: var(--op-space-scale-unit);
  width: var(--notification-badge-size);
  height: var(--notification-badge-size);
  background-color: var(--gc-color-secondary-original);
  border-radius: var(--op-radius-circle);

  .btn--icon & {
    position: absolute;
    top: var(--op-space-x-small);
    right: 0;
  }
}

.read-badge {
  display: flex;
  align-items: flex-start;
  gap: var(--op-space-scale-unit);
  width: var(--notification-badge-size);
  height: var(--notification-badge-size);
  background-color: var(--op-color-neutral-plus-two);
  border-radius: var(--op-radius-circle);
}

.notifications.styled-container {
  gap: 0;
}

.notification-body {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-2x-small);
  padding-bottom: var(--op-space-medium);
  padding-left: var(--op-space-2x-small);
}

.notification-header__title {
  color: var(--op-color-neutral-on-plus-max);
  font-family: var(--op-font-family);
  font-size: var(--op-font-large);
  font-style: normal;
  font-weight: var(--op-font-weight-bold);
  line-height: var(--op-line-height-dense);
  text-transform: uppercase;

  &::after {
    content: '•';
    padding-inline: var(--op-space-2x-small);

    @media screen and (max-width: $breakpoint-x-small) {
      content: none;
    }
  }

  @media screen and (max-width: $breakpoint-x-small) {
    font-size: var(--op-font-x-large);
  }
}

.notification-header__subtitle {
  color: var(--op-color-neutral-on-plus-max-alt);
  font-weight: var(--op-font-weight-bold);
  line-height: var(--op-line-height-dense);
}

.notification-description {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: var(--op-space-3x-small) var(--op-space-2x-small);
  align-self: stretch;
  flex-wrap: wrap;
  @include report-body;

  &__bold {
    font-weight: 600;
  }

  &__summary {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.notification-date {
  @include meta-text;
  display: flex;
  align-items: center;
  font-size: var(--op-font-small);
  line-height: var(--op-line-height-dense);
  gap: var(--op-space-2x-small);
}

.notification-icon {
  display: inline;
}

.icon-badge {
  line-height: unset;
  gap: 0px;
}

.notification-wrapper .notification-preview {
  --__notification-border-top-height: calc(var(--op-size-unit) * 10);
  --__notification-border-badge-left: calc(var(--op-size-unit) * -1.25);

  &:first-child {
    .notification-border__top {
      border-left-color: transparent;
    }
  }

  &:last-child {
    .notification-border__bottom {
      border-left-color: transparent;
    }
  }

  .notification-border__top,
  .notification-border__bottom {
    border-left: 1px solid var(--op-color-neutral-plus-two);
  }

  .notification-border__top {
    height: var(--__notification-border-top-height);
    position: relative;

    .read-badge,
    .unread-badge {
      position: absolute;
      bottom: 0;
      left: var(--__notification-border-badge-left);
    }
  }

  .notification-border__bottom {
    height: calc(100% - var(--__notification-border-top-height));
  }
}

.section-header.section-header--notification {
  @media screen and (max-width: $breakpoint-x-small) {
    flex-direction: unset;
  }
}
