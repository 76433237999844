%breadcrumbs-global {
  --_op-breadcrumbs-font-size-small: var(--gc-font-small-minus-one);
  min-height: var(--op-space-2x-large);

  @media (max-width: $breakpoint-small) {
    padding-inline-start: 0;
  }

  .breadcrumbs__link {
    @extend %link;
    line-height: var(--op-space-x-large); // Needs to be 24px tall
  }

  .breadcrumbs__link,
  .breadcrumbs__text {
    white-space: nowrap;
  }

  .breadcrumbs__text {
    font-weight: var(--op-font-weight-bold);
  }

  .breadcrumbs__link:not(:last-child,.breadcrumbs__back-button)::after,
  .breadcrumbs__text:not(:last-child,.breadcrumbs__back-button)::after {
    content: " › ";
    display: inline-block; // prevents the slash from inheriting the text decoration of the link
    padding-inline-start: var(--op-space-x-small);
    color: var(--op-color-neutral-on-plus-max);
  }

  .breadcrumbs__back-button::after {
    content: " |";
    display: inline-block; // prevents the slash from inheriting the text decoration of the link
    padding-inline-start: var(--op-space-x-small);
    color: var(--op-color-neutral-on-plus-max);
    font-size: 10px;
  }
}
