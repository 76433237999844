.contact-relationship-manager__image {
  display: flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  border-radius: var(--op-radius-small);

  img {
    background-color: var(--gc-avatar-background);
    border-radius: var(--op-radius-small);
  }
}

.contact-sidebar__image {
  box-shadow: var(--op-border-all) var(--op-color-border);
}

textarea.form-control.contact-message {
  max-width: 300px;
  font-size: var(--op-font-x-small);
}
