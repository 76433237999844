%menu-global {
  --_op-menu-max-width: 25rem;
  background-color: var(--op-color-neutral-plus-max);
  color: var(--op-color-neutral-on-plus-max);
  box-shadow: var(--op-border-all) var(--op-color-border);
  border-radius: var(--gc-radius-default);
  max-width: var(--_op-menu-max-width);

  .menu__section {
    padding: var(--op-space-x-small) 0;

    &:not(:first-child) {
      border-top: solid var(--op-border-width) var(--op-color-border);
    }
  }

  .menu-item {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: var(--op-font-small);
    padding: var(--op-space-2x-small) var(--op-space-small);
    cursor: pointer;
    color: inherit;

    .menu-item__text {
      flex-grow: 1;
      padding: 0 var(--op-space-2x-small);
    }

    &:hover {
      background-color: var(--op-color-neutral-plus-eight);
      color: var(--op-color-neutral-on-plus-eight);
    }

    &.selected {
      background-color: var(--op-color-primary-base);
      color: var(--op-color-primary-on-base);
    }
  }
}

.menu {
  @extend %menu-global;
}

sl-dropdown .menu {
  --__gc-dropdown-min-width: 300px;
  --__gc-dropdown-max-height: 500px;

  min-width: var(--__gc-dropdown-min-width);

  .menu__section {
    max-height: var(--__gc-dropdown-max-height);
    overflow-y: auto;
    box-shadow: none;

    .menu-item {
      text-align: left;
    }

    &:not(:first-child) {
      border-top: none;
    }
  }
}

.menu-navbar {
  @extend %menu-global;
  background-color: var(--gc-color-navbar-background);
  color: var(--gc-color-navbar);
  box-shadow: none;
  border-radius: var(--gc-radius-default);
  border-top-left-radius: 0;
  .menu-item {
    font-size: var(--op-font-medium);
  }
  .menu-item:hover {
    background-color: transparent;
    color: var(--gc-color-secondary-original);
  }
}

// Dropdown

.dropdown {
  position: relative;

  .menu {
    position: absolute;
    top: 100%;
    width: max-content;
    z-index: calc(var(--op-z-index-header) + 1);

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    }
  }

  .menu-navbar {
    position: absolute;
    top: var(--op-input-height-medium);
    width: max-content;
    min-width: 100%;
    z-index: calc(var(--op-z-index-header) + 1);
    transform: scaleY(0); // *squish*
    transform-origin: top;
    transition: var(--gc-transition-menu);

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    }

    @media screen and (max-width: $breakpoint-small) {
      position: relative;
      top: unset;
      width: 100%;
      max-width: unset;

      .menu__section {
        padding: 0;
      }
    }
  }
}

.dropdown[open] .menu-navbar {
  transform: scaleY(1);
}
