.form-group .ts-wrapper {
  grid-column: 1/3;
}

.form-group .ts-wrapper .ts-control,
.ts-wrapper.single.input-active .ts-control {
  border-radius: var(--gc-radius-default);
  box-shadow: var(--op-border-all) var(--op-color-neutral-plus-three);
  font-weight: var(--op-font-weight-normal);
  padding: var(--op-space-2x-small) var(--op-space-x-small) var(--op-space-2x-small) var(--op-space-small);


  & > input {
    font-size: var(--op-font-small);
    color: var(--op-color-neutral-on-plus-max);
  }

  // Hover State
  &:hover:not(:disabled, [readonly]) {
    box-shadow: var(--op-border-all) var(--op-color-primary-plus-three);
  }

  &:focus-within:not(:disabled, [readonly]),
  &:hover:focus-within:not(:disabled, [readonly]) {
    background-color: var(--op-color-primary-plus-seven);
    box-shadow: var(--op-input-focus-primary);
    color: var(--op-color-primary-on-plus-seven);
  }


  .form-group--error & {
    box-shadow: var(--op-border-all) var(--op-color-alerts-danger-base);

    &:hover {
      box-shadow: var(--op-border-all) var(--op-color-alerts-danger-base);
      background-color: var(--op-color-alerts-danger-plus-seven);
    }
  }
}

.ts-wrapper.multi.input-active .ts-control,
.ts-wrapper.tom_select.multi.focus.input-active .ts-control {
  background-color: var(--op-color-primary-plus-seven);
  box-shadow: var(--op-input-focus-primary);
  color: var(--op-color-primary-on-plus-seven);
}

.ts-wrapper.plugin-remove_button .ts-control .item {
  --__item-border-color: var(--gc-btn-primary-text);

  color: var(--gc-btn-primary-text);
  background-color: var(--gc-btn-primary-background);
  padding: var(--op-space-3x-small) var(--op-space-x-small);
  gap: var(--op-space-2x-small);
  border-radius: var(--op-radius-medium);

  a.remove {
    border-color: var(--op-color-border);
    padding-inline: var(--op-space-x-small);
    margin-left: var(--op-space-3x-small);
  }

  &.item--outline {
    --__item-border-color: var(--op-color-primary-on-plus-max);

    color: var(--op-color-primary-on-plus-max);
    background-color: var(--op-color-primary-plus-max);
    box-shadow: var(--op-border-all) var(--__item-border-color);
  }
}

.ts-wrapper .ts-dropdown {
  background-color: var(--op-color-neutral-plus-max);
  color: var(--op-color-neutral-on-plus-max);
  top: calc(100% + var(--op-border-width-large) + var(--op-space-3x-small));
  border-radius: var(--op-radius-medium);
  box-shadow: var(--op-border-all) var(--op-color-border), var(--op-shadow-medium);

  .ts-dropdown-content {
    --_ts-items-to-show: 11;
    --_ts-items-height: 29.5px;
    --_ts-items-padding: var(--op-space-x-small);

    padding-block: var(--_ts-items-padding);
    max-height: calc((var(--_ts-items-to-show) * var(--_ts-items-height)) + (var(--_ts-items-padding) * 2));


    @media screen and (max-width: $breakpoint-small) {
      --_ts-items-to-show: 6;
    }

    .option {
      padding: var(--op-space-2x-small) var(--op-space-small);
      line-height: var(--op-line-height-base);
    }

    // Use Optics' spinner
    .spinner::after {
      content: unset;
    }

    .create {
      color: var(--op-color-neutral-plus-one);
    }
  }

  .optgroup-header {
    background-color: var(--op-color-neutral-plus-five);
    color: var(--op-color-neutral-on-plus-five);
    font-weight: var(--op-font-weight-semi-bold);
  }
}
