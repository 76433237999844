sl-tooltip {
  --max-width: unset;
  --sl-tooltip-border-radius: var(--op-radius-medium);
  --sl-tooltip-background-color: var(--op-color-neutral-plus-max);
  --sl-tooltip-font-family: var(--op-font-family);
  --sl-tooltip-font-size: var(--op-font-size-x-small);
  --sl-tooltip-font-weight: var(--op-font-weight-normal);
  --sl-tooltip-line-height: var(--op-line-height-base);
  --sl-tooltip-color: var(--op-color-neutral-on-plus-max);
  --sl-tooltip-padding: var(--op-space-small) var(--op-space-medium);

  &::part(body) {
    box-shadow: var(--op-shadow-medium);
    border: 1px solid var(--op-color-border);
  }
}

sl-popup {
  &::part(arrow) {
    box-shadow: var(--op-shadow-medium);
    border: 1px solid var(--op-color-border);
  }
}
