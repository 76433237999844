%text-pair-global {
  --_op-text-pair-font-size-2x-large: var(--op-font-2x-large);
  --_op-text-pair-font-size-x-large: var(--op-font-x-large);

  // Gimme Credit aligns most things to the baseline. Following that pattern here.
  &.text-pair--inline {
    align-items: baseline;
  }

  .text-pair__title {
    line-height: var(--op-line-height-base);
    font-weight: var(--op-font-weight-medium);

    a {
      color: inherit;
    }

    &.text-pair__title--2x-large {
      --__op-text-pair-title-font-size: var(--_op-text-pair-font-size-2x-large);
    }

    &.text-pair__title--x-large {
      --__op-text-pair-title-font-size: var(--_op-text-pair-font-size-2x-large);
    }
  }

  h1 {
    margin: 0;
  }
}
