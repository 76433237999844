sl-menu-item,
gc-menu-item {
  --sl-color-neutral-700: var(--gc-text-color); // Color
  --sl-spacing-x-small: var(--op-space-2x-small); // Gap between slots

  &:focus-visible{
    --sl-color-primary-600: var(--op-color-neutral-plus-seven); // Background-color
    --sl-color-neutral-0: var(--op-color-neutral-on-plus-seven); // Color
  }
  &:hover {
    --sl-color-neutral-100: var(--op-color-neutral-plus-seven); // Background-color
    --sl-color-neutral-1000: var(--op-color-neutral-on-plus-seven); // Color
  }

  &::part(submenu-icon),
  &::part(checked-icon) {
    display: none;
  }
}
