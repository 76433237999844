.faq-preview {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-x-small);
  box-shadow: var(--op-border-bottom) var(--op-color-border);
}

.faq-header {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-x-small);
}

.faq-header__title {
  font-size: var(--op-font-x-large);
  line-height: var(--op-line-height-dense);
  color: var(--op-color-neutral-on-plus-max);
  margin-bottom: 0;
}
