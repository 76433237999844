.flatpickr-calendar.rangeMode.inline {
  box-shadow: none;
  border: none;
  width: 100%;

  .flatpickr-innerContainer {
    width: 100%;

    .flatpickr-rContainer {
      width: 100%;
    }

    .flatpickr-days {
      width: 100%;

      .flatpickr-day {
        max-width: unset;
        max-height: unset;

        &.inRange {
          box-shadow: none;
        }
      }
    }

    .dayContainer {
      width: 100%;
      max-width: unset;
      justify-content: flex-start;
    }
  }

  .flatpickr-months {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-template-areas: 'date previous next';

    .flatpickr-month {
      grid-area: date;
      text-align: left;

      .flatpickr-current-month {
        left: 0;
        text-align: left;
      }
    }

    .flatpickr-prev-month {
      grid-area: previous;
    }

    .flatpickr-next-month {
      grid-area: next;
    }

    .flatpickr-next-month,
    .flatpickr-prev-month {
      position: relative;

      &.flatpickr-disabled {
        display: block;
        opacity: 0.5;

        .btn {
          opacity: var(--op-opacity-disabled);
          pointer-events: none;
          user-select: none;
        }
      }
    }
  }
}

.flatpickr-calendar.rangeMode {
  --__flatpickr-default-text-color: var(--op-color-neutral-minus-eight);
  --__flatpickr-range-background: var(--op-color-neutral-plus-six);

  box-shadow: 0px 7px 14px 0px var(--op-color-border);
  border-top: solid var(--op-border-width) var(--op-color-border);
  background: var(--op-color-neutral-plus-max);
  color: var(--__flatpickr-default-text-color);

  .flatpickr-monthDropdown-months {
    color: var(--__flatpickr-default-text-color);
    font-weight: var(--op-font-weight-medium);
    line-height: var(--op-line-height-dense);
  }

  .flatpickr-days {
    .dayContainer {
      &:not(:first-child) {
        padding-left: var(--op-space-medium);
        box-shadow: -1px 0 0 var(--op-color-border);
      }
      &:not(:last-child) {
        padding-right: var(--op-space-medium);
      }

      .flatpickr-day {
        color: var(--op-color-neutral-on-plus-max);
        font-size: var(--op-font-medium);
        font-weight: var(--op-font-weight-semi-bold);

        &:hover {
          border-color: var(--op-color-neutral-plus-five);
          background: var(--op-color-neutral-plus-five);
        }

        &.flatpickr-disabled {
          color: var(--op-color-neutral-plus-five);
        }

        &.prevMonthDay,
        &.nextMonthDay {
          opacity: 0.44;
        }

        &.startRange,
        &.endRange,
        &.selected {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: var(--gc-btn-primary-background);
          color: var(--gc-btn-primary-text);
          border-radius: var(--gc-radius-default);
          line-height: var(--op-line-height-densest);
          font-weight: var(--op-font-weight-medium);
          transition: var(--gc-transition-btn-hover);
          font-size: var(--op-font-size-large);
          text-align: center;
          border: none;
          box-shadow: none;

          &:hover {
            background-color: var(--gc-btn-hover-background);
            color: var(--gc-btn-hover-text);
          }
        }

        &.startRange {
          border-end-end-radius: 0;
          border-start-end-radius: 0;
        }

        &.endRange {
          border-start-start-radius: 0;
          border-end-start-radius: 0;
        }

        &.startRange + .endRange {
          &:not(:nth-child(7n+1)) {
            box-shadow: -10px 0 0 var(--gc-btn-primary-background);
          }
        }

        &.inRange {
          background: var(--__flatpickr-range-background);
          box-shadow: -5px 0 0 var(--__flatpickr-range-background), 5px 0 0 var(--__flatpickr-range-background);
          border: none;
          color: var(--gc-btn-text);
        }
      }
    }

    .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
      box-shadow: 5px 0 0 var(--__flatpickr-range-background), 5px 0 0 var(--__flatpickr-range-background);
    }
  }

  .flatpickr-weekdays {
    .flatpickr-weekday {
      color: var(--op-color-neutral-plus-three);
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: var(--op-font-weight-medium);
      line-height: var(--op-line-height-dense);
    }
  }

  .flatpickr-months {
    .flatpickr-next-month, .flatpickr-prev-month {
      height: auto;
    }

    .flatpickr-month {
      height: calc(12 * var(--op-size-unit));
      display: flex;
      align-items: center;

      .flatpickr-current-month {
        padding-top: 0;
        height: auto;

        .cur-month, .cur-year {
          color: var(--__flatpickr-default-text-color);
          font-weight: var(--op-font-weight-medium);
          line-height: var(--op-line-height-dense);
        }
      }
    }
  }

  .shortcut-buttons-flatpickr-wrapper {
    margin-top: var(--op-space-x-small);
    margin: var(--op-space-medium);

    .shortcut-buttons-flatpickr-buttons {
      border-top: solid var(--op-border-width) var(--op-color-border);
      padding-top: var(--op-space-small);
      text-align: right;
    }
  }

  .btn.btn--small {
    --__op-btn-height: var(--_op-btn-height-small);
    --__op-btn-font-size: var(--_op-btn-font-small);
    --__op-btn-padding: var(--_op-btn-padding-small);
  }
}
