.footer {
  &.navbar-primary {
    padding: var(--op-space-x-small) var(--op-space-medium);
    justify-content: space-between;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    align-items: flex-start;
    flex-direction: column-reverse;
    .btn-navbar {
      --_op-btn-padding-large: 0;
    }
  }

  &__links {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $breakpoint-small) {
      align-items: flex-end;
      flex-direction: column;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__link-group {
    align-items: center;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $breakpoint-small) {
      align-items: flex-end;
      flex-direction: column;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__social-links {
    display: flex;
    gap: var(--op-space-x-small);
  }
}

.copyright {
  font-size: var(--op-font-x-small);
  color: var(--gc-color-navbar-text);
  line-height: var(--op-line-height-base);
  text-align: left;
}
