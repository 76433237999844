$pto-width: 360px;

.pto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--op-space-large);
  border-radius: var(--gc-radius-default);
  border: var(--op-border-width) solid var(--op-color-neutral-plus-five);
  background: var(--op-color-neutral-plus-max);
}

.pto-form {
  padding: var(--op-space-large);
  border-radius: var(--gc-radius-default);
  border: var(--op-border-width) solid var(--op-color-neutral-plus-five);
  background: var(--op-color-neutral-plus-max);

  .form-row {
    gap: var(--op-space-large);

    .form-group {
      padding-top: 0;
    }
  }

  .form-row__actions {
    margin: 0;
  }
}

.scheduled-pto {
  background-color: var(--op-color-neutral-base);
  box-shadow: var(--op-border-all) var(--op-color-neutral-base);
  color: var(--op-color-neutral-on-base);
  padding: var(--op-space-x-small) var(--op-space-medium);
  border-radius: var(--gc-radius-default);
}

.pto-cta {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--op-space-large);
}

.pto-box {
  background: var(--op-color-neutral-plus-seven);

  p {
    padding: var(--op-space-3x-large);
    text-align: center;
  }
}

.pto-frame {
  min-width: $pto-width;
}
