.research-section {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-x-large);
  padding-inline: var(--op-space-scale-unit);

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--op-space-3x-small);
    align-self: stretch;
  }

  &__title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: var(--op-color-neutral-on-plus-max);
    font-size: var(--op-font-x-large);
    font-weight: var(--op-font-weight-bold);
    line-height: var(--op-line-height-base);

    .material-symbols-outlined {
      --__icon-font-large: 32px;
      --__icon-weight-thick: 200;

      font-size: var(--__icon-font-large);
      --op-mso-grade: var(--__icon-weight-thick);
    }
  }

  &__description {
    width: 100%;
    color: var(--op-color-neutral-on-plus-max);
    font-size: 15px;
    font-weight: var(--op-font-weight-normal);
    line-height: var(--op-line-height-base);
  }
}
