:root {
  --op-font-family: "Inter", sans-serif;
  --sl-font-sans: var(--op-font-family);
  font-feature-settings: 'liga' 1, 'calt' 1;
  /* fix for Chrome */
}

@supports (font-variation-settings: normal) {
  :root {
    --op-font-family: "InterVariable", sans-serif;
    --gc-font-optical-sizing: auto;
    --gc-font-italic: "slnt" -6; // only Inter is available in italic variant settings
  }
}

@mixin font-scales {
  --gc-font-small-minus-one: calc(var(--op-font-scale-unit) * 1.3);
  --gc-font-smallest: calc(var(--op-font-scale-unit) * 1.1);
}

@mixin font-weights {
  --op-font-weight-light: 330;
  --op-font-weight-normal: 390;
  --op-font-weight-medium: 460;
  --op-font-weight-semi-bold: 530;
  --op-font-weight-bold: 650;
  --gc-font-weight-bolder: 725;
}

@mixin letter-spacing {
  --gc-letter-spacing-tight: -0.01em;
}


// This is used in the admin section but not in the current Gimme Credit design.
@mixin page-title {
  font-weight: var(--op-font-weight-bold);
  font-size: var(--op-font-4x-large);
  color: var(--gc-text-color);
  margin: 0
}

// TODO This needs a better name. Currently used reused .styled-container__group-title and on top of .admin-page-header__title
@mixin section-title {
  font-size: var(--op-font-medium);
  font-weight: var(--op-font-weight-bold);
  line-height: var(--op-line-height-dense);
  text-transform: uppercase;
  margin: 0;
}

// Headings used in client side of the app.
%heading {
  --_gc-heading-font-size: var(--op-font-large);
  --_gc-heading-font-weight: var(--op-font-weight-bold);
  --_gc-heading-text-transform: none;
  color: var(--gc-text-color);
  font-size: var(--_gc-heading-font-size);
  line-height: var(--op-line-height-dense);
  letter-spacing: var(--gc-letter-spacing-tight);
  font-weight: var(--_gc-heading-font-weight);
  text-transform: var(--_gc-heading-text-transform);
  margin-block: 0;

  &.heading--x-large {
    --_gc-heading-font-size: var(--op-font-x-large);
  }

  &.heading--x-large-mobile {
    @media screen and (max-width: $breakpoint-x-small) {
      font-size: var(--op-font-x-large);
      font-weight: var(--op-font-weight-bold);
    }
  }

  &.heading--large {
    --_gc-heading-font-size: var(--op-font-large);
  }

  &.heading--medium {
    --_gc-heading-font-size: var(--op-font-medium);
    --_gc-heading-font-weight: var(--op-font-weight-semi-bold);
  }

  &.heading--small {
    --_gc-heading-font-size: var(--op-font-small);
    --_gc-heading-font-weight: var(--op-font-weight-semi-bold);
  }

  &.heading--x-small {
    --_gc-heading-font-size: var(--gc-font-small-minus-one);
    --_gc-heading-font-weight: var(--op-font-weight-semi-bold);
  }

  &.heading--all-caps {
    --_gc-heading-text-transform: uppercase;
  }

  &.heading--all-caps-desktop {
    @media screen and (min-width: $breakpoint-x-small) {
      --_gc-heading-text-transform: uppercase;
    }
  }
}

@mixin report-title {
  font-size: var(--op-font-large);
  font-weight: var(--op-font-weight-bold);
  line-height: var(--op-line-height-dense);
  margin: 0;
}

@mixin header-title {
  font-size: var(--op-font-large);
  font-weight: var(--op-font-weight-semi-bold);
  line-height: var(--op-line-height-base);
  text-transform: uppercase;
  margin-bottom: 0;
  text-align: center;

  @media (max-width: $breakpoint-small) {
    text-align: left;
  }
}

// shared between .report__summary and .report__body
@mixin report-body {
  color: var(--gc-text-color);
  font-weight: var(--op-font-weight-normal);
  line-height: var(--op-line-height-base);
  /* This needs to be 150% for accessibility */
  margin-block: 0;
  text-wrap: pretty;

  @media screen and (min-width: $breakpoint-x-small) {
    font-size: var(--gc-font-small-minus-one);
  }

  b,
  strong {
    font-weight: var(--op-font-weight-bold);
  }
}

@mixin credit-score {
  font-size: var(--op-font-x-small);
  font-weight: var(--op-font-weight-bold);
  line-height: var(--op-line-height-dense);

  &.score--positive {
    color: var(--op-color-alerts-notice-minus-three);
  }

  &.score--negative {
    color: var(--op-color-alerts-danger-base);
  }
}

// Paragraph/body/summary text when seen in the sidebar.
@mixin aside-text {
  font-size: var(--op-font-x-small);
  line-height: var(--op-line-height-base);
  font-weight: var(--op-font-weight-normal);
  line-height: var(--op-line-height-base);

  b,
  strong {
    font-weight: var(--op-font-weight-bold);
  }
}

// Text that is secondary to its surroundings and should be less prominent. Like date, analyst name, etc.
@mixin meta-text {
  color: var(--gc-text-meta);
  font-weight: var(--op-font-weight-normal);
  line-height: var(--op-line-height-densest);
  margin-block: 0;
}

// Used in admin and on public pages from old design. Waiting for the new design to be discovered before changing this.
.admin-page-header__title {
  @include page-title;
  flex: 1;
}

// Current page title used throughout in the old style. Waiting for the new design to be discovered before changing this.
.page-section-title {
  @include section-title;
}

.heading {
  @extend %heading;
}

// This should be phased out
.page-title {
  @extend %heading;
}


a.page-title {

  &:hover {
    color: var(--op-color-primary-plus-one);
  }
}

.font-size--x-small {
  font-size: var(--op-font-x-small);
}

.text-uppercase {
  text-transform: uppercase;
}

:root {
  @include letter-spacing;
  @include font-scales;
  @include font-weights;
}
