.note {
  justify-content: space-between;
  flex-direction: row;
  .note__content {
    display: flex;
    flex-direction: column;
    gap: var(--op-space-small);
  }
  .note__info {
    display: flex;
    align-items: baseline;
    gap: var(--op-space-2x-small);
  }
  .note__author {
    margin: 0;
    font-size: var(--op-font-medium);
    font-weight: var(--op-font-weight-medium);
    line-height: var(--op-line-height-base);
  }
  .note__date {
    @include meta-text;
    line-height: var(--op-line-height-base);
  }
  .note__body {
    @include report-body;
    & {
      margin: 0;
    }
  }
}

.notes-container {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-medium);
}
