.publisher-notes__tag {
  color: var(--gc-btn-primary-text);
  font-size: var(--op-font-x-small);
  font-weight: var(--op-font-weight-bold);
  text-decoration: none;
  line-height: var(--op-line-height-looser);
  display: inline-block;
  padding-inline: var(--op-space-x-small);
  background-color: var(--gc-btn-primary-background);
  border-left: var(--gc-brand-tag-width) solid var(--gc-color-secondary-original);
}

.publisher-note {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--op-space-3x-small);

  .publisher-note__date {
    @include meta-text;

    font-size: var(--op-font-x-small);
    line-height: var(--op-line-height-dense);
  }

  .publisher-note__body .trix-content, .publisher-note__body a {
    font-size: var(--op-font-medium);
    font-style: normal;
    font-weight: var(--op-font-weight-medium);
    line-height: var(--op-line-height-densest);
  }
}
