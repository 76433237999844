%sidebar-global {
  --_op-sidebar-drawer-width: calc(65 * var(--op-size-unit));
  --_op-sidebar-background-color: var(--op-color-neutral-plus-max);
  --_op-sidebar-text-color: var(--op-color-neutral-on-plus-max);
  --_op-sidebar-border-color: var(--op-color-border);
  --_op-sidebar-brand-spacing: var(--op-space-medium);
  --_op-sidebar-content-spacing: var(--op-space-x-small);
  padding-block: var(--op-space-3x-large);
  gap: var(--op-space-x-large);

  .sidebar__content--scroll-y {
    overflow-y: auto;
  }

  .divider {
    flex-shrink: 0;
  }

  .sidebar__content {

    > * {
      justify-content: flex-start;

      .material-symbols-outlined.icon--weight-normal {
        @extend .icon--weight-normal;
      }
    }
  }
}

.sidebar .sidebar__content {
  .accordion summary {
    padding-inline: var(--op-space-x-small);
  }
  .btn {
    justify-content: flex-start;
    padding-inline: var(--op-space-x-small);
  }

  a {
    display: flex;
  }
}

.sidebar__content.sidebar__content--end {
  display: flex;
}

.sidebar .sidebar__brand {
  height: auto;
  padding: var(--op-space-x-small) var(--_op-sidebar-brand-spacing);
  margin: 0;
  svg {
      height: calc(6 * var(--op-size-unit)); // 24px
  }
}
