.download-filters {
  display: flex;
  align-items: center;
  align-content: center;
  gap: var(--op-space-x-small);
  align-self: stretch;
  flex-wrap: wrap;

  @media screen and (max-width: $breakpoint-x-small) {
    gap: var(--op-space-medium);
  }

  .filter-control {
    flex-basis: calc((100% - (2 * var(--op-space-x-small))) / 3);

    @media screen and (max-width: $breakpoint-x-small) {
      flex-basis: 100%;
    }
  }
}

.download-button {
  @media screen and (max-width: $breakpoint-x-small) {
    width: 100%;
  }
}

.download-sections {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-large);

  @media screen and (max-width: $breakpoint-x-small) {
    gap: var(--op-space-x-large);
  }
}

.download-section {
  display: flex;
  flex-direction: column;

  .download-section__inputs {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--op-space-large);

    @media screen and (max-width: $breakpoint-x-small) {
      flex-direction: column;
    }
  }
}

.download-input-wrapper {
  display: flex;
  gap: var(--op-space-x-small);
  align-items: center;

  &.download-input-wrapper--disabled label {
    cursor: not-allowed;
    opacity:  var(--op-opacity-disabled);
  }

  button {
    opacity:  var(--op-opacity-disabled);
  }
}

.download-data--description {
  color: var(--gc-text-meta);
  font-size: var(--op-font-size-x-small);
  font-style: normal;
  font-weight: var(--op-font-weight-medium);
  line-height: var(--op-line-height-base);
  padding-left: calc(var(--op-space-x-small) + var(--op-space-x-large)); // Line up with the label of the radiobox

  @media screen and (max-width: $breakpoint-x-small) {
    padding-left: calc(var(--op-space-x-small) + var(--op-space-x-large));
  }
}

.filter-control__trigger {
  @media screen and (width > $breakpoint-x-small) {
    --__op-btn-height: var(--_op-btn-height-small);
    --__op-btn-font-size: var(--_op-btn-font-small);
    --__op-btn-padding: var(--_op-btn-padding-small);
    max-width: 100%;
  }
}
