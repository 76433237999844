:root {
  --gc-color-chart-range-max: hsl(276, 69%, 35%);
  --gc-color-chart-range-high: hsl(0, 98%, 41%);
  --gc-color-chart-range-mid: hsl(53, 100%, 50%);
  --gc-color-chart-range-low: var(--gc-color-secondary-original);
  --gc-color-chart-threshold: hsl(11, 100%, 36%);

  // Group range theme
  --gc-color-chart-group: var(--op-color-primary-plus-seven);

  --gc-chart-grid-color: var(--op-color-neutral-plus-four);

  --gc-color-chart-highlight: var(--op-color-primary-minus-six);
  --gc-color-chart-highlight-border: var(--op-color-primary-plus-max);
  --gc-color-chart-highlight-label-background: var(--gc-color-chart-highlight);
  --gc-color-chart-highlight-label-text: var(--op-color-primary-on-minus-max);
  --gc-color-chart-highlight-line: var(--gc-color-chart-highlight);

  --gc-color-chart-text: var(--gc-text-color);
  --gc-color-chart-alt-text: hsl(213, 4%, 54%);


  --gc-color-chart-threshold-label-background: var(--gc-color-chart-threshold);
  --gc-color-chart-threshold-label-text: var(--op-color-white);
  --gc-color-chart-threshold-line: var(--gc-color-chart-threshold);

  --gc-color-chart-tooltip-background: var(--op-color-neutral-plus-max);
  --gc-color-chart-tooltip-border: var(--op-color-border);
  --gc-color-chart-tooltip-text: var(--op-color-neutral-on-plus-max);


  --gc-chart-height: 58vh;
  --gc-chart-actions-input-width: calc(var(--op-size-unit) * 100); // 240px
}

.chart-container {
  position: relative;

  .chart-container__header {
    h1 {
      @extend %heading, .heading--x-large;
    }
  }
}

.chart-wrapper {
  position: relative;
  width: 100%;

  canvas {
    height: 100%;
    width: 100%;
  }
}

.chart-actions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--gc-chart-actions-input-width), 1fr));
  gap: var(--op-space-medium);

  width: 100%;

  .form-group {
    padding: 0;
    flex: 1 1 0
  }
}

.btn--chart-reset-zoom {
  position: absolute;
  right: var(--op-space-small);
  top: var(--op-space-3x-large);
  transition: var(--op-transition-input);
}
