:root {
  --op-encoded-images-search-icon-width: calc(4 * var(--op-size-unit));
  --op-encoded-images-search-icon: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAnIGhlaWdodD0nMjAnIHZpZXdCb3g9JzAgMCAyMCAyMCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTcuMTUgMTguMkwxMC44NSAxMS45MjVDMTAuMzUgMTIuMzQxNyA5Ljc3NSAxMi42NjY3IDkuMTI1IDEyLjlDOC40NzUgMTMuMTMzMyA3LjgwODMzIDEzLjI1IDcuMTI1IDEzLjI1QzUuNDA4MzMgMTMuMjUgMy45NTgzMyAxMi42NTgzIDIuNzc1IDExLjQ3NUMxLjU5MTY3IDEwLjI5MTcgMSA4Ljg0MTY3IDEgNy4xMjVDMSA1LjQyNSAxLjU5MTY3IDMuOTgzMzMgMi43NzUgMi44QzMuOTU4MzMgMS42IDUuNDA4MzMgMSA3LjEyNSAxQzguODI1IDEgMTAuMjY2NyAxLjU5MTY3IDExLjQ1IDIuNzc1QzEyLjYzMzMgMy45NTgzMyAxMy4yMjUgNS40MDgzMyAxMy4yMjUgNy4xMjVDMTMuMjI1IDcuODQxNjcgMTMuMTA4MyA4LjUyNSAxMi44NzUgOS4xNzVDMTIuNjQxNyA5LjgyNSAxMi4zMjUgMTAuMzkxNyAxMS45MjUgMTAuODc1TDE4LjIgMTcuMTVMMTcuMTUgMTguMlpNNy4xMjUgMTEuNzVDOC40MDgzMyAxMS43NSA5LjQ5MTY3IDExLjMgMTAuMzc1IDEwLjRDMTEuMjc1IDkuNSAxMS43MjUgOC40MDgzMyAxMS43MjUgNy4xMjVDMTEuNzI1IDUuODQxNjcgMTEuMjc1IDQuNzUgMTAuMzc1IDMuODVDOS40OTE2NyAyLjk1IDguNDA4MzMgMi41IDcuMTI1IDIuNUM1LjgyNSAyLjUgNC43MjUgMi45NSAzLjgyNSAzLjg1QzIuOTQxNjcgNC43NSAyLjUgNS44NDE2NyAyLjUgNy4xMjVDMi41IDguNDA4MzMgMi45NDE2NyA5LjUgMy44MjUgMTAuNEM0LjcyNSAxMS4zIDUuODI1IDExLjc1IDcuMTI1IDExLjc1WicgZmlsbD0nIzY3Njc2NycvPjwvc3ZnPgo=");
}

%search-icon {
  padding-left: var(--op-space-2x-large);
  appearance: none;
  background-image: var(--op-encoded-images-search-icon);
  background-position: center left;
  background-position-x: calc(var(--op-encoded-images-search-icon-width) / 2);
  background-repeat: no-repeat;
  background-size: var(--op-encoded-images-search-icon-width);
  cursor: pointer;
}

%form-control-input-global {
  --_op-form-control-font-large: var(--op-font-medium);
  --__op-form-control-height: var(--_op-form-control-height-large);

  border-radius: var(--gc-radius-default);
  background-color: var(--op-color-neutral-plus-max);
  box-shadow: var(--op-border-all) var(--op-color-border);

  &[readonly] {
    font-weight: var(--op-font-weight-semi-bold);
    font-size: var(--op-font-small);
  }

  &.form-control--search {
    @extend %search-icon;
  }
}

::placeholder,
::-webkit-input-placeholder {
  color: var(--op-color-border);
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  &:focus {
    background-color: var(--gc-btn-focus-background);
    color: var(--gc-btn-focus-text);
  }
}

.form-container {
  --_op-form-container-shadow: 0 1px 3px 0 hsl(var(--op-color-neutral-h), var(--op-color-neutral-s), 0%, 0.05);
  width: 100%;
  padding: var(--op-space-3x-large);
  background-color: var(--op-color-neutral-plus-max);
  color: var(--op-color-neutral-on-plus-max);
  box-shadow: var(--tbt-container-shadow);
  border-radius: var(--tbt-radius-default);
}

.form-row {
  display: flex;
  gap: var(--op-space-x-large);

  & > * {
    flex: 1;
  }

  .form-group {
    flex: 1 1 0px;

    &.form-group--compact {
      gap: 0;
      padding: var(--op-space-x-small) 0;
    }
  }

  &.form-row__actions {
    gap: var(--op-space-small);
    align-items: center;
    justify-content: flex-end;
    margin-bottom: var(--op-space-small);
  }

  .form-group--error~.form-row__actions {
    margin-bottom: var(--op-space-3x-large);
  }
}

.form-section-title {
  font-size: var(--op-font-large);
  font-weight: var(--op-font-weight-semi-bold);
}

.td-actions {
  display: flex;
  align-items: center;
  gap: var(--op-space-x-small);
}

label.form-label.form-label--small {
  font-size: var(--op-font-small);
}

.form-group textarea.form-control {
  height: auto;
}

input.date {
  &:focus-within {
    @extend :focus-visible;
  }
}
