.archive__filter {
  .archive__filter-controls {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: var(--op-space-x-small);

    .filter-control__trigger {
      padding: 0 var(--op-space-small);
      gap: var(--op-space-2x-small);
      font-weight: var(--op-font-weight-semi-bold);
    }

    @media screen and (max-width: $breakpoint-x-small) {
      flex-direction: column;
      gap: var(--op-space-medium);
      align-items: center;
      align-content: center;
      align-self: stretch;
      flex-wrap: wrap;

      .filter-control {
        width: 100%;
      }
    }
  }

  .archive__filter-reset {
    justify-self: end;
  }

  .archive__filter-label {
    font-size: var(--op-font-small);
    display: flex;
    align-items: center;

    @media screen and (max-width: $breakpoint-x-small) {
      display: none;
    }
  }
}

.archive__filter-labels {
  display: none;
  flex-wrap: wrap;
  gap: var(--op-space-x-small);

  &:has(button:not(.display-none)) {
    display: flex;
  }
}

.archive-reports__container {
  --gc-box-gap: var(--op-space-x-large);
  @extend %styled-container;
  container: main-container;
  align-self: flex-start;
}

.archive__filter-labels {
  &.btn--small {
    gap: var(--op-space-2x-small);
  }
  &.btn--small, .btn--small {
    --__op-btn-height: var(--_op-btn-height-small);
    --__op-btn-font-size: var(--_op-btn-font-small);
    --__op-btn-padding: var(--_op-btn-padding-small);
    max-width: 100%;

    span:not(.material-symbols-outlined) {
      max-width: calc(100% - 16px - var(--op-space-2x-small));
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

select.form-control.form-control--archive-sort {
  --__op-form-control-height: 28px;
  --__archive-sort-min-width: 140px;

  padding-block: 0;
  padding-right: var(--op-space-2x-large);
  font-size: var(--op-font-x-small);
  line-height: var(--op-line-height-densest);
  min-width: var(--__archive-sort-min-width);
  width: auto;

  @media screen and (max-width: $breakpoint-x-small) {
    width: 100%;
  }
}

gc-menu-item.archive__search-result {
  &::part(base) {
    padding: var(--op-space-x-small);
  }

  &:not(:last-child) {
    border-bottom: var(--op-border-width) solid var(--op-color-neutral-plus-four);
  }

  .search__result {
    padding: 0;
  }
}

.archive__filter-reset,
.archive__filter-date-clear {
  font-size: var(--op-font-small);
  column-gap: var(--op-space-x-small);
  align-items: center;
  padding: var(--op-space-small) var(--op-space-medium);
  font-weight: var(--op-font-weight-semi-bold);

  @media screen and (max-width: $breakpoint-x-small) {
    font-size: var(--op-font-medium);
  }

  .material-symbols-outlined {
    padding-right: var(--op-space-3x-small);

    @media screen and (max-width: $breakpoint-x-small) {
      padding-right: var(--op-space-x-small);
    }
  }
}

.archive__date-range-header {
  font-size: var(--op-font-x-large);
  font-weight: var(--op-font-weight-semi-bold);
  line-height: var(--op-line-height-base);
  min-height: unset;
  padding: 0;
  gap: var(--op-space-2x-small);
}
