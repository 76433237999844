:root {
  --gc-schedule-item-size: calc(6 * var(--op-size-unit)); // 24px
}

.scheduled-count {
  border: solid var(--op-border-width) var(--op-color-neutral-minus-one);
  height: var(--gc-schedule-item-size);
  width: var(--gc-schedule-item-size);
  display: flex;
  border-radius: var(--op-radius-circle);
  font-size: var(--op-font-x-small);
  align-items: center;
  justify-content: center;
}

.schedule-table td,
.schedule-table th {
  padding: var(--op-space-small) var(--op-space-x-small);
  text-align: left;

  &.text-center {
    text-align: center;
  }
}

td.schedule-item {
  width: var(--gc-schedule-item-size);
}

.schedule-table tr {
  box-shadow: var(--op-border-bottom) var(--op-color-border);

  &.table-legend {
    box-shadow: none;
  }
}

.schedule-table .btn {
  height: var(--gc-schedule-item-size) !important;
  width: var(--gc-schedule-item-size) !important;
  border-radius: var(--op-radius-circle);
  min-height: unset;
  padding: unset;
  box-shadow: var(--op-border-all) var(--op-color-border);
  transition: all 150ms;

  &:hover {
    transform: scale(1.25);
  }
}

.schedule-table .btn--scheduled {
  background-color: var(--op-color-alerts-warning-plus-six);
  box-shadow: var(--op-border-all) var(--op-color-alerts-warning-plus-one);
}

.schedule-table .btn--selected {
  background-color: var(--op-color-alerts-notice-plus-five);
  box-shadow: var(--op-border-all) var(--op-color-alerts-notice-plus-one);
}

.schedule-table .btn--late {
  background-color: var(--op-color-alerts-danger-plus-five);
  box-shadow: var(--op-border-all) var(--op-color-alerts-danger-plus-two);
}

.schedule-table .btn--no-report {
  background-color: var(--op-color-neutral-plus-six);
  box-shadow: var(--op-border-all) var(--op-color-neutral-minus-six);
  color: var(--op-color-neutral-on-plus-six-alt);
  font-weight: bold;
  display: flex;
  align-items: center;
}

.schedule-table .btn--pto {
  background-color: var(--op-color-neutral-base);
  box-shadow: var(--op-border-all) var(--op-color-neutral-minus-four);
  color: var(--op-color-neutral-on-base);
  font-weight: bold;
  display: flex;
  align-items: center;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: var(--op-space-x-small);
}

.current-day-indicator {
  display: inline-block;
  width: var(--gc-schedule-item-size);
  height: var(--gc-schedule-item-size);
  background-color: var(--op-color-alerts-danger-plus-one);
  color: var(--op-color-alerts-danger-on-plus-one);
  border-radius: var(--op-radius-circle);
}

.late-date {
  display: none;

  &.visible {
    display: flex;
  }
}
