.spaced {
  & > :not(:last-child),
  &.spaced--with-last > * {
    &::after {
      align-self: center;
      display: block;
      text-align: center;
      font-size: var(--op-font-x-small);
      font-weight: var(--op-font-weight-normal);
      line-height: var(--op-line-height-base);
      content: '…'
    }
  }

  &.spaced--hide-sm > * {
    @media screen and (max-width: $breakpoint-small) {
      &::after {
        display: none;
      }
    }
  }

  &.spaced--hide-xs > * {
    @media screen and (max-width: $breakpoint-x-small) {
      &::after {
        display: none;
      }
    }
  }
}

@mixin separator {
  & > :not(:last-child),
  &.separated--with-last > * {
    content: "";
    width: 100%;
    border-bottom: 1px solid var(--op-color-neutral-plus-four);
    padding-bottom: var(--gc-separated-spacing);
  }
}

@media screen and (max-width: $breakpoint-x-small) {
  .separated-mobile {
    @include separator;
  }
}

.separated {
  @include separator;
}
