.flexible-layout-area.company-layout-area {
  @media screen and (max-width: $breakpoint-x-small) {
    flex-direction: column-reverse;
  }
}

.company__underlying-view {
  color: var(--gc-color-dark-text);
}

.ticker {
  color: var(--op-color-neutral-on-plus-max-alt);
}

.score {
  @include credit-score;
}

.company__underlying-view {
  @include report-body;
}

.underlying-view {
  @include aside-text;
}

.upgrade-downgrade {
  .upgrade-downgrade__date {
    display: flex;
    gap: var(--op-space-small);
    font-size: var(--op-font-x-small);
    align-items: center;
    color: var(--op-color-neutral-on-plus-max-alt);
    white-space: nowrap;
  }

  .upgrade {
    color: var(--op-color-alerts-notice-minus-one);
  }

  .downgrade {
    color: var(--op-color-alerts-danger-base);
  }
}

table.table.company-table {
  @media screen and (max-width: $breakpoint-x-small) {
    // Adjust the table--disintegrate-mobile to use flex-wrap on the row so that sector and industry can be inline
    tr {
      flex-direction: row;
      flex-wrap: wrap;
    }

    td.company-table__date {
      --__table-disintegrate-column-order: 0;
      width: 100%;

      a {
        @include meta-text;
        font-size: var(--gc-font-small-minus-one);
        font-weight: var(--op-font-weight-normal);
        line-height: var(--op-line-height-densest);
        text-decoration: none;
      }
    }

    .company-table__name {
      --__table-disintegrate-column-order: 1;
      width: 100%;

      a:not(.link) {
        color: var(--gc-text-default);
        font-size: var(--op-font-x-large);
        font-weight: var(--op-font-weight-bold);
        line-height: var(--op-line-height-dense);
        letter-spacing: 0.2px;
      }

      .ticker {
        display: none;
      }
    }

    .company-table__sector {
      --__table-disintegrate-column-order: 2;

      color: var(--gc-text-default);
      font-size: var(--op-font-medium);
      font-weight: var(--op-font-weight-medium);
      line-height: var(--op-line-height-base);

      &::after {
        padding-left: var(--op-space-2x-small);
        content: '/'
      }
    }

    .company-table__industry {
      --__table-disintegrate-column-order: 3;

      color: var(--gc-text-default);
      font-size: var(--op-font-medium);
      font-weight: var(--op-font-weight-medium);
      line-height: var(--op-line-height-base);
    }
  }
}
