%table-global {
  border-radius: var(--gc-radius-default);
  box-shadow: none;
  border-collapse: separate;
  border-spacing: 0;
  border: var(--op-border-width) solid var(--op-color-border);
  overflow: visible;
  contain: unset;

  th,
  td {
    font-size: var(--op-font-small);

    a:not(.btn):not(.menu-item) {
      @extend %link;
    }
  }

  td {
    background-color: var(--op-color-neutral-plus-max);
    color: var(--op-color-neutral-on-plus-max);
  }

  td.align-baseline {
    vertical-align: baseline;
  }

  th.align-right,
  td.align-right {
    text-align: right;
  }

  th.align-left,
  td.align-left {
    text-align: left;
  }

  thead {
    background-color: var(--op-color-neutral-plus-seven);
  }


  tbody {

    tr:not(:last-child) {
      td {
        box-shadow: inset var(--op-border-top) var(--op-color-border);
      }
    }

    tr:last-child {
      td {
        box-shadow: none;
      }
    }
  }

  &.table--fixed-layout {

    td,
    td a {
      white-space: nowrap;
      overflow: hidden;
      /* <- this does seem to be required */
      text-overflow: ellipsis;
    }
  }
}

.table-x-scroll-container {
  width: 100%;
  overflow-x: auto;
  box-shadow: var(--op-border-all) var(--op-color-border);
  border-radius: var(--gc-radius-default);

  .table {
    border: 0;
  }

  thead {
    tr {
      th:first-of-type {
        background-color: var(--op-color-neutral-plus-seven);
        position: sticky;
        left: 0;
        box-shadow: inset var(--op-border-left) var(--op-color-border), inset var(--op-border-top) var(--op-color-border);
      }
    }
  }

  tbody {
    tr {
      td:first-of-type {
        box-shadow: var(--op-border-bottom) var(--op-color-border);
        position: sticky;
        left: 0;
      }

      &:not(:last-child) {
        td:first-of-type {
          box-shadow: inset var(--op-border-left) var(--op-color-border), inset var(--op-border-top) var(--op-color-border);
        }
      }

      &:last-child {
        td:first-of-type {
          box-shadow: inset var(--op-border-left) var(--op-color-border);
        }
      }
    }
  }
}

.table-x-scroll-container.no-border {
  box-shadow: none;
}

.table-striped.table {
  border: none;

  td, th {
    font-size: var(--gc-font-small-minus-one);
  }

  td {
    background-color: inherit;
    color: inherit;
  }

  thead {
    background-color: var(--op-color-neutral-plus-max);
    color: var(--op-color-neutral-on-plus-max);
    box-shadow: none;

    tr th {
      background-color: var(--op-color-neutral-plus-max);
      color: var(--op-color-neutral-on-plus-max);
      white-space: nowrap;
      box-shadow: none;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  tbody {
    tr {
      box-shadow: none;

      td {
        box-shadow: none !important;

        &.number {
          font-feature-settings: "liga" 1, "calt" 1, "tnum";
          text-align: right;
        }
      }

      &:nth-child(even) {
        background-color: var(--gc-table-zebra-background);
        color: var(--op-color-neutral-on-plus-max);
      }

      &:nth-child(odd) {
        background-color: var(--op-color-neutral-plus-max);
        color: var(--op-color-neutral-on-plus-max);
      }
    }
  }
}

.table-group {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-small);
}

// When this is applied to a table, it will "disintegrate" into a flex layout on mobile that puts all "columns" on their
// own line and add a border spacer between each "row". The column order can be adjusted by setting the
// "--__table-disintegrate-column-order" on each td to re-arrange where it appears in the mobile layout
.table.table--disintegrate-mobile {
  --__table-disintegrate-column-order: 0;

  @media screen and (max-width: $breakpoint-x-small) {
    thead {
      display: none;
    }

    tbody {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      gap: var(--op-space-x-large);

      tr {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: var(--op-space-2x-small);

        &:not(:last-child) {
          padding-bottom: var(--op-space-large);
          border-bottom: 1px solid var(--op-color-neutral-plus-four);
        }

        td {
          padding: 0;
          height: auto;
          order: var(--__table-disintegrate-column-order);
        }

        &:nth-child(even) {
          background-color: var(--op-color-neutral-plus-max);
        }
      }
    }
  }
}
