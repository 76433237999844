.avatar {
  --_op-avatar-inner-border-width: 0;
  --_op-avatar-outer-border-width: 0;
  --_op-avatar-size-large: calc(25 * var(--op-size-unit)); // 100px

  img {
    filter: grayscale(100%);
  }

  &__small {
    --_op-avatar-size-large: calc(15 * var(--op-size-unit)); // 60px
  }
}
