// this can be used anytime there is a list of reports in a column
.reports-list {
  --gc-separated-spacing: var(--op-space-medium);

  display: flex;
  flex-direction: column;
  gap: var(--gc-separated-spacing);

  @media screen and (max-width: $breakpoint-x-small) {
    --gc-separated-spacing: var(--op-space-large);
  }

  &__title {
    @include header-title;
  }

  &--empty {
    @include aside-text;
  }
}

.link--primary-mobile {
  @media screen and (max-width: $breakpoint-x-small) {
    --gc-link-color: var(--gc-link-primary-color);
  }
}

article:has(.report) {
  width: 100%;
  max-width: calc(188 * var(--op-size-unit));
  margin: 0 auto;
}

.category {
  color: var(--op-color-alerts-info-on-plus-five-alt);
  font-weight: bold;
  font-size: var(--op-font-small);
}

.profile {
  font-size: 5rem;
}

tr.unapproved td {
  color: var(--op-color-alerts-danger-base);
}

.report-overrides {
  display: none;

  &.visible {
    display: flex;
  }
}

// Use in admin reports preview
.report-preview--wrapper {
  border: var(--op-border-width) solid var(--op-color-border);
  background-color: var(--op-color-neutral-plus-max);
  width: fit-content;
}

.body-content {
  justify-content: flex-start;
}

.copyright-report-wrapper {
  display: flex;
  justify-content: center;

  .copyright {
    color: var(--op-color-neutral-on-plus-max);
    max-width: 100%;
  }
}

.reports__container {
  --gc-box-gap: var(--op-space-x-large);
  @extend %styled-container;
  container: main-container / inline-size;
  align-self: flex-start;
}

.reports__column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--op-font-2x-large);
  flex: 1 0 0;
}

.report-link a {
  color: var(--op-color-neutral-on-plus-max);
}

// The main report component. There are several overrides when used in report-preview.
.report {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--op-space-x-small);

  &__bond-info {
    flex-wrap: wrap;
    display: flex;
    gap: var(--op-space-3x-small) var(--op-space-x-small);
  }

  &__actions {
    align-items: center;
    border-radius: var(--op-radius-small);
    display: flex;
    justify-content: flex-end;
    gap: var(--op-space-x-small);
    font-size: var(--gc-font-small-minus-one);

    @media screen and (max-width: $breakpoint-x-small) {
      justify-content: flex-start;
      font-size: var(--op-font-medium);
    }
  }

  &__credit_score {
    color: var(--gc-color-dark-text);
    font-family: var(--op-font-family);
    font-size: var(--op-font-x-small);
    font-style: normal;
    font-weight: var(--op-font-weight-bold);
    line-height: var(--op-line-height-base);
  }

  &__container {
    @extend %styled-container;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: baseline;
    align-self: stretch;
    gap: var(--op-space-2x-small);

    &.top-bottom {
      align-items: center;

      @media screen and (max-width: $breakpoint-x-small) {
        align-items: baseline;
      }
    }

    @container main-container (inline-size < 400px) {
      flex-direction: column-reverse;
    }

    @container aside-container (inline-size < 280px) {
      flex-direction: column-reverse;
    }

    @media (max-width: $breakpoint-x-small) {
      flex-direction: column;
    }
  }

  &__header-group {
    display: flex;
    align-items: baseline;
    gap: var(--op-space-3x-small) var(--op-space-2x-small);
    align-self: stretch;
    flex-wrap: wrap;

    @container (max-width: 450px) {
      flex-direction: column-reverse;
    }
  }

  &__company {
    font-size: var(--op-font-medium);
    font-weight: var(--op-font-weight-bold);
    line-height: var(--op-line-height-dense);
    text-transform: uppercase;
    letter-spacing: var(--gc-letter-spacing-tight);

    @media screen and (max-width: $breakpoint-x-small) {
      font-size: var(--op-font-x-large);
    }

    &.report__company-top-ten {
      display: flex;
      align-items: center;
      flex-direction: row;
      color: var(--gc-color-secondary-on-plus-eight-alt);
    }
    &.report__company-bottom-ten {
      display: flex;
      align-items: center;
      flex-direction: row;
      color: var(--op-color-alerts-danger-on-plus-seven-alt);
    }
  }

  &__ticker {
    color: var(--op-color-neutral-on-plus-max);
    font-size: var(--op-font-medium);
    font-style: normal;
    font-weight: var(--op-font-weight-normal);
    line-height: var(--op-line-height-dense);
    text-transform: uppercase;
  }

  &__company-list {
    color: var(--op-color-neutral-on-plus-max);
  }

  &__industry {
    color: var(--op-color-neutral-on-plus-max-alt);

    font-family: var(--op-font-family);
    font-size: var(--gc-font-small-minus-one);
    font-style: normal;
    font-weight: var(--op-font-weight-normal);
    line-height: var(--op-line-height-denser);
  }

  &__product {
    font-weight: var(--op-font-weight-normal);
    color: var(--gc-color-alerts-danger-custom);
    line-height: var(--op-line-height-densest);

    @media screen and (min-width: $breakpoint-x-small) {
      font-size: var(--gc-font-small-minus-one);
    }
  }

  &__product-top-bottom {
    @include meta-text;

    font-family: var(--op-font-family);
    font-size: var(--gc-font-small-minus-one);
    font-weight: var(--op-font-weight-normal);
    line-height: var(--op-line-height-base);

    @media screen and (max-width: $breakpoint-x-small) {
      font-size: var(--op-font-medium);
    }
  }

  &__date {
    @include meta-text;
    white-space: nowrap;
    text-align: right;
    flex: 1;
    font-size: var(--gc-font-small-minus-one);
    line-height: var(--op-line-height-dense);

    @media screen and (max-width: $breakpoint-x-small) {
      font-size: var(--op-font-x-small);
    }

    &--x-small {
      font-size: var(--op-font-x-small);
    }
  }

  &__title-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-self: stretch;
    gap: var(--op-space-2x-small);

    @media (max-width: $breakpoint-x-small) {
      justify-content: flex-start;
      align-items: flex-start;
    }

    @media print {
      width: 100%;
      align-items: center;
    }
  }

  // used to group items together at the beginning of a flex container
  &__group-start {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: baseline;
    gap: 0 var(--op-space-2x-small);

    &.items-center {
      align-items: center;
    }
  }

  &__title a {
    line-height: var(--op-line-height-looser);
  }

  &__title {
    @include report-title;

    &--primary-mobile {
      @media screen and (max-width: $breakpoint-x-small) {
        color: var(--gc-link-primary-color);
      }
    }
  }

  &__author {
    @include meta-text;
    font-size: var(--op-font-small);
  }

  &__body {
    @include report-body;

    & {
      align-self: stretch;
    }
  }

  &__summary {
    @include report-body;
  }

  &__score-text {
    color: var(--op-color-neutral-on-plus-max);
    text-align: right;

    font-family: var(--op-font-family);
    font-size: var(--op-font-x-small);
    font-weight: var(--op-font-weight-bold);
  }

  &__cap-line {
    font-weight: var(---op-font-weight-semi-bold);
    padding: var(--op-space-medium) 0;
    border-top: 1px solid var(--op-color-border);
    font-size: var(--op-font-small);
    align-self: stretch;
  }

  &__data-sheet-link {
    display: flex;
    /*CSS3*/
    align-items: center;
    /*Vertical align*/
    font-size: var(--gc-font-small-minus-one);

    @media (pointer: coarse) {
      min-height: var(--op-space-2x-large);
    }

    @media (pointer: fine) {
      min-height: var(--op-space-large);
    }
  }

  &__badge {
    @media screen and (max-width: $breakpoint-x-small) {
      font-size: var(--op-font-x-small);
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    gap: var(--op-space-x-small);

    @media screen and (max-width: $breakpoint-x-small) {
      align-items: flex-start;
      flex-direction: column;
      gap: var(--op-space-small);
    }
  }
}

.report-links {
  font-size: var(--gc-font-small-minus-one);
  line-height: var(--op-line-height-base);
  /* 150% */
  padding-inline-start: var(--op-space-small);

  @media screen and (max-width: $breakpoint-x-small) {
    padding-inline-start: 0;
    display: flex;
    flex-direction: column;
    gap: var(--op-space-2x-small);
  }

  &__link {
    font-weight: var(--op-font-weight-normal);

    @media screen and (max-width: $breakpoint-x-small) {
      font-size: var(--op-font-medium);
    }
  }
}

.report-info__group {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: var(--op-space-2x-small);
}

// Overrides for report-preview
.report-preview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--op-color-neutral-on-plus-max);

  .report-preview__info {
    display: flex;
    align-items: flex-end;
    gap: var(--op-space-x-small);

    @media screen and (max-width: $breakpoint-x-small) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  .report-info__start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--op-space-3x-small);
    flex-grow: 1;

    @media screen and (max-width: $breakpoint-x-small) {
      gap: var(--op-space-x-small);
    }
  }

  .report__title {
    font-size: var(--op-font-small);
    font-weight: var(--op-font-weight-normal);

    @media screen and (max-width: $breakpoint-x-small) {
      font-size: var(--op-font-large);
    }

    &.report__title--x-small {
      font-size: var(--op-font-x-small);
    }

    &.report__title--bold {
      font-weight: var(--op-font-weight-bold);
    }
  }

  .report__title-group {
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--op-space-2x-small);

    @media screen and (max-width: $breakpoint-x-small) {
      flex-direction: column-reverse;
    }
  }

  .report__author {
    text-transform: none;
    font-size: var(--gc-font-small-minus-one);
    line-height: var(--op-line-height-dense);

    &.report__author--x-small {
      font-size: var(--op-font-x-small);
    }

    @media screen and (max-width: $breakpoint-x-small) {
      font-size: var(--op-font-medium);
    }
  }

  .trix-content {
    padding-bottom: 0px;
  }

  .report-header {
    gap: var(--op-space-3x-small);
  }

  .report__underlying-view {
    color: var(--gc-color-dark-text);
    font-family: var(--op-font-family);
    font-size: var(--op-font-x-small);
    font-style: normal;
    font-weight: var(--op-font-weight-bold);
    line-height: var(--op-line-height-base);
  }
}

.report-print-header {
  &__top {
    align-items: center;
    display: flex;
    height: var(--op-space-2x-large);
    justify-content: space-between;
  }

  &__bottom {
    border-top: 0.5px solid var(--op-color-neutral-plus-three);
    padding-top: var(--op-space-x-small);
    display: flex;
    justify-content: space-between;
  }

  &__company-name {
    color: var(--gc-color-navbar-background);
    font-size: var(--op-font-large);
    font-weight: var(--op-font-weight-extra-bold);
    line-height: var(--op-line-height-base);
    text-transform: uppercase;
  }

  &__author {
    color: var(--op-color-neutral-on-plus-max-alt);
    font-family: var(--op-font-family);
    font-size: var(--op-font-small);
    font-weight: var(--op-font-weight-normal);
    line-height: var(--op-line-height-dense);
  }

  &__email {
    text-decoration-line: underline;
  }

  &__date {
    color: var(--op-color-neutral-on-plus-max-alt);
    font-family: var(--op-font-family);
    font-size: var(--op-font-small);
    font-weight: var(--op-font-weight-normal);
    line-height: var(--op-line-height-dense);
  }
}

.report-print-footer {
  position: absolute;
  bottom: var(--op-space-medium);
  left: 0;
  width: var(--op-breakpoint-medium);
  padding-inline: calc(var(--letter-width-padding) + var(--op-space-small));

  @media print {
    & &__copyright {
      color: var(--op-color-neutral-on-plus-three);
      font-size: var(--op-font-small);
      line-height: var(--op-line-height-base);

      border-top: 0.5px solid var(--op-color-neutral-plus-three);
    }
  }
}

.report-accordion.accordion {
  summary {
    border: 1px solid var(--op-color-border);
  }

  .accordion-header {
    font-weight: var(--op-font-weight-bold);
  }
}

.report__top-bottom-prefix {
  white-space: pre;
  display: none;

  @media print {
    display: inline-block;
  }
}
