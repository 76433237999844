.analytics {
  .no-data {
    color: var(--gc-text-meta);
    font-size: var(--op-font-x-medium);
    font-family: var(--op-font-family);
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  table.report-results, table.distribution-results {
    tr:nth-child(1) td span.icon--filled {
      color: var(--op-color-alerts-info-plus-one);
    }
    tr:nth-child(2) td span.icon--filled {
      color: var(--op-color-alerts-info-plus-two);
    }
    tr:nth-child(3) td span.icon--filled {
      color: var(--op-color-alerts-info-plus-three);
    }
    tr:nth-child(4) td span.icon--filled {
      color: var(--op-color-alerts-info-plus-four);
    }
    tr:nth-child(5) td span.icon--filled {
      color: var(--op-color-alerts-info-plus-five);
    }
  }

  &.account-grid {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-template-areas:
      '. . .'
      '. activity activity'
      '. companies companies';
  }

  &.publications-grid {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-template-areas:
    '. . .'
    'type referrer referrer'
    'type engagement engagement'
    '. analysts analysts';
  }

  &.publication-grid {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-template-areas:
    '. . .'
    '. activity activity'
    '. activity activity';
  }

  &.distributions-grid {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-template-areas:
    '. . .'
    'type opens opens'
    'type times times'
  }

  &.distribution-grid {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-template-areas:
    '. . .'
    'opens companies companies'
    'opens companies companies'
  }

  &.index-grid {
    display: grid;
    grid-template-columns: repeat(3, 33%);
  }

  &__container {
    @extend %styled-container;
    height: 100%;

    .analytics & {
      gap: 0;
    }

    .analytics__divider {
      padding-block: var(--op-space-2x-small);
    }

    &.opens-area {
      grid-area: opens;
    }

    &.times-area {
      grid-area: times;
    }

    &.activity-area {
      grid-area: activity;
    }

    &.analysts-area {
      grid-area: analysts;
    }

    &.companies-area {
      grid-area: companies;
    }

    &.engagement-area {
      grid-area: engagement;
    }

    &.referrer-area {
      grid-area: referrer;
    }

    &.type-area {
      grid-area: type;
    }

    .card-header {
      color: var(--op-color-neutral-on-plus-max);

      font-size: var(--op-font-2x-large);
      font-weight: var(--op-font-weight-bold);
    }

    .chart-header {
      color: var(--op-color-neutral-on-plus-max);

      font-size: var(--op-font-small);
    }

    .chart-info {
      &::after {
        font-family: "Material Symbols Outlined";
        content: "\e88e"; // icon 'info'
      }
    }

    .chart-title {
      color: var(--op-color-neutral-on-plus-max);

      font-size: var(--op-font-3x-large);
      font-weight: var(--op-font-weight-bold);
    }

    .chart-description {
      color: var(--op-color-neutral-on-plus-max-alt);

      font-size: var(--op-font-x-small);
    }

    .chart-label {
      color: var(--op-color-neutral-on-plus-max);

      font-family: var(--op-font-family);
      font-size: var(--op-font-x-small);
    }

    .increasing {
      color: var(--op-color-alerts-notice-base);
      &::after {
        font-family: "Material Symbols Outlined";
        content: "\e5c7"; // icon 'arrow_drop_up'
      }
    }

    .decreasing {
      color: var(--op-color-alerts-danger-base);
      &::after {
        font-family: "Material Symbols Outlined";
        content: "\e5c5"; // icon 'arrow_drop_down'
      }
    }
  }
}
