.privacy {
  color: var(--op-color-neutral-on-plus-max);
  font-family: var(--op-font-family);
  font-size: var(--op-font-x-small);
  font-style: normal;
  font-weight: var(--op-font-weight-normal);
  line-height: var(--op-line-height-base);

  @media screen and (max-width: $breakpoint-x-small) {
    font-size: var(--op-font-medium);
  }

  ul {
    margin-block: 0 var(--op-space-small); // This should be more global but sticking it here for now.
  }
}
