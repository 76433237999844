.feedback__card {
  display: flex;
  max-width: 492px;
  align-items: center;
  align-self: stretch;
}

.feedback__row {
  display: flex;
  gap: var(--op-space-2x-small);
  --op-gap: var(--op-space-2x-small);
}

.feedback__label {
  color: var(--op-color-neutral-on-plus-eight);
  text-align: center;
  font-size: var(--op-font-small);
  font-style: normal;
  font-weight: var(--op-font-weight-bold);
  line-height: var(--op-line-height-base);
}

.feedback__value {
  color: var(--op-color-neutral-on-plus-eight-alt);
  text-align: center;
  font-size: var(--op-font-small);
  font-style: normal;
  font-weight: var(--op-font-weight-normal);
  line-height: var(--op-line-height-base);
}
