html.mobile-app {
  overflow: initial;

  body {
    overflow: unset;
  }
}

body {
  --op-color-background: var(--gc-color-background);
  --op-color-on-background: var(--gc-text-color);

  font-optical-sizing: var(--gc-font-optical-sizing);

  @media (width <= $breakpoint-x-small) {
    --op-color-background: var(--op-color-neutral-plus-max);
    --op-color-on-background: var(--gc-text-color);
  }
  &.admin {
    --op-color-background: var(--op-color-neutral-plus-eight);
    --op-color-on-background: var(--gc-text-color);

    .app-body {
      height: 100%;
    }
  }
}

a {
  text-decoration: none;
  color: var(--gc-link-color);

  // Focus State
  &:focus-visible {
    outline: none;
    box-shadow: var(--op-input-focus-primary);
    border-radius: var(--gc-radius-default);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
}

p {
  font-size: var(--gc-font-small-minus-one);
  @media screen and (max-width: $breakpoint-x-small) {
    font-size: var(--op-font-medium);
  }
}

// hide mobile safari details summary arrow
details summary::-webkit-details-marker {
  display: none;
}
