
.sheet-row {
  display: flex;
  gap: var(--op-space-x-large);
}

.details-sheet__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--op-space-x-large);
}

.detail__group {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-2x-small);
  flex: 1 1 0px;
  flex-basis: calc(33% - var(--op-space-x-large));
}

.detail__key {
  font-size: var(--op-font-x-small);
}

.detail__value {
  font-size: var(--op-font-small);
  font-weight: var(--op-font-weight-semi-bold);
}

.detail__value--emphasis {
  font-size: var(--op-font-x-large);
  font-weight: var(--op-font-weight-bold);
}
