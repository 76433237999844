%accordion-global {
  summary {
    .accordion__label {
      font-size: var(--op-font-small);
      line-height: var(--op-line-height-base);
      font-weight: var(--op-font-weight-medium);
    }
  }
}

.accordion.accordion__chevron {
  summary {
    &::after {
      content: '\e313';
      font-family: "Material Symbols Outlined";
      --op-mso-optical-sizing: 48;
      font-size: var(--op-font-x-large);
      justify-self: flex-end;
      transition: var(--op-transition-accordion);
      user-select: none;
    }
  }

  &[open] summary {
    margin-bottom: var(--op-space-small);

    &::after {
      rotate: -0.5turn;
    }
  }

  @media screen and (max-width: $breakpoint-x-small) {
    summary {
      width: 100%;
      border-radius: var(--op-radius-medium);
      border: 1px solid var(--op-color-neutral-plus-four);
      background: var(--op-color-neutral-plus-seven);
      padding: var(--op-space-x-small) var(--op-space-small);
      align-items: center;
      gap: var(--op-space-2x-small);
    }
  }
}

.mobile-details-header {
  font-size: var(--op-font-medium);
  font-weight: var(--op-font-weight-bold);
  line-height: var(--op-line-height-dense);
  text-transform: uppercase;
  margin: 0px;

  @media screen and (max-width: $breakpoint-x-small) {
    text-transform: none;
    line-height: var(--op-line-height-base);
  }

  &.mobile-details-header--centered {
    @media screen and (width > $breakpoint-x-small) {
      text-align: center;
    }
  }

  &.mobile-details-header--small {
    @media screen and (width > $breakpoint-x-small) {
      font-size: var(--op-font-small);
      font-weight: var(--op-font-weight-semi-bold);
      text-transform: none;
    }
  }
}
