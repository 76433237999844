.relationship-manager {
  &__container {
    display: flex;
    gap: var(--op-space-3x-small);
    align-items: baseline;
    justify-content: center;
    padding: var(--op-space-2x-small) var(--op-space-large);
    line-height: var(--op-line-height-base);
    box-shadow: var(--op-border-all) var(--gc-relationship-manager-border);
    background: var(--op-color-primary-minus-three);
    color: var(--gc-color-navbar-text);

    @media (width <= $breakpoint-large) {
      display: none;
    }
  }

  .link {
    line-height: var(--op-line-height-base);
  }

  &__label {
    font-size: var(--gc-font-small-minus-one);
    font-weight: var(--op-font-weight-normal);
    line-height: var(--op-line-height-base);
  }

  &__email {
    display: flex;
    gap: var(--op-space-3x-small);

    font-size: var(--gc-font-small-minus-one);
    font-weight: var(--op-font-weight-semi-bold);
    line-height: var(--op-line-height-base);
  }

  &__mail-icon {
    text-decoration-line: none;

    .material-symbols-outlined {
      color: var(--gc-color-secondary-base);
    }
  }
}

.btn-navbar.btn-relationship-manager {
  padding: var(--_op-navbar-horizontal-spacing);
}
