.side-column {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  gap: var(--op-space-x-large);

  @media screen and (max-width: $breakpoint-medium) {
    gap: var(--op-space-medium);
  }

  .report__date {
    font-size: var(--op-font-x-small);
  }

  .report__summary {
    @include aside-text;
  }

  .trix-content {
    padding: 0;
  }
}

.company-link-list,
.sector-link-list {
  columns: 2;
  gap: 0 var(--op-space-small);

  &.company-link-list__four-columns {
    columns: 4;

    @media screen and (max-width: $breakpoint-small) {
      columns: 2;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      columns: 1;
    }
  }

  @media screen and (max-width: $breakpoint-x-small) {
    columns: 1;
  }

  .link {
    display: inline-block;
    width: 100%;
    font-size: var(--gc-font-small-minus-one);
    text-transform: uppercase;
    line-height: var(--op-line-height-loosest); // Needs to be 24px tall

    @media screen and (max-width: $breakpoint-x-small) {
      font-size: var(--op-font-medium);
    }
  }
}

