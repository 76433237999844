
@media screen and (max-width: $breakpoint-x-small) {
  .app__content {
    &:has(.card-login) {
      padding: 0;
    }

    .container {
      &:has(.card-login) {
        padding: 0;
      }
    }
  }

  .subscribe-text {
    padding-inline: var(--op-space-small);
  }
}

// Styles that apply specifically to the login page on mobile app
.mobile-app:has(.card-login) {
  .app__header {
    display: none;
  }
}

.subscribe-text {
  color: var(--op-color-on-background);

  .subscribe-header {
    font-family: var(--op-font-family);
    font-size: var(--op-font-5x-large);
    font-weight: var(--op-font-weight-black);
    padding-bottom: var(--op-space-medium);
    line-height: var(--op-line-height-dense);

    @media screen and (max-width: $breakpoint-small) {
      font-size: var(--op-font-3x-large);
    }
  }
}

.login__header {
  color: var(--op-color-neutral-on-plus-max);
  font-size: var(--op-font-x-large);
  font-style: normal;
  font-weight: var(--op-font-weight-bold);
  line-height: var(--op-line-height-dense);
}
