.analyst-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(332px, 1fr));
  align-items: center;
  gap: var(--op-space-2x-large);

  @media screen and (max-width: $breakpoint-x-small) {
    gap: var(--op-space-large);
  }
}

.analyst-heading {
  color: var(--op-color-neutral-on-plus-max);
  font-family: var(--op-font-family);
  display: flex;
  flex-direction: column;
  gap: var(--op-space-x-large);

  @media screen and (max-width: $breakpoint-x-small) {
    gap: var(--op-space-x-small);
  }

  &__title {
    font-weight: var(--op-font-weight-semi-bold);
    line-height: var(--op-line-height-dense);
    font-size: var(--op-font-medium);

    @media screen and (max-width: $breakpoint-x-small) {
      font-weight: var(--op-font-weight-bold);
      font-size: var(--op-font-x-large);
    }
  }

  &__contact {
    font-size: var(--gc-font-small-minus-one);
    font-style: normal;
    line-height: var(--op-line-height-base);
    font-weight: var(--op-font-weight-semi-bold);

    @media screen and (max-width: $breakpoint-x-small) {
      font-weight: var(--op-font-weight-normal);
      font-size: var(--op-font-medium);
    }
  }
}

// Analyst Direct Reports/Bio Page
.analyst {
  &__research-heading {
    text-align: center;
    font-size: var(--op-font-large);

    @media screen and (max-width: $breakpoint-x-small) {
      text-align: left;
      font-size: var(--op-font-x-large);
    }
  }

  &__bio {
    @include aside-text;

    & {
      width: 100%;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      font-size: var(--op-font-medium);
    }
  }

  &__industries,
  &__products,
  &__regions,
  &__sectors,
  &__countries {
    @include aside-text;

    @media screen and (max-width: $breakpoint-x-small) {
      font-size: var(--op-font-medium);
    }
  }
}

.analyst__container {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-large);
  align-items: center;

  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;

  grid-template-areas:
    'photo info'
    'photo email';

  @media screen and (max-width: $breakpoint-small) {
    display: grid;
  }

  @media screen and (width > $breakpoint-small) {
    &:not(.analyst__container--inline) {
      .analyst__photo-wrapper {
        --__analyst-photo-dimension: calc(var(--op-space-scale-unit) * 24); // 240px
      }
    }
  }

  @media screen and (max-width: $breakpoint-x-small) {
    column-gap: var(--op-space-small);
    grid-template-areas:
      'photo info'
      'email email';
  }

  .analyst__email {
    display: none;
  }

  .analyst__email-box {
    display: flex;
  }

  &.analyst__container--inline {
    display: grid;
    column-gap: var(--op-space-medium);
    row-gap: var(--op-space-3x-small);
    align-items: flex-start;

    @media screen and (max-width: $breakpoint-x-small) {
      column-gap: var(--op-space-small);
      grid-template-areas:
        'photo info'
        'photo email';
    }

    .analyst__info-wrapper {
      align-items: flex-start;
    }

    .analyst__title {
      text-align: left;
    }

    .analyst__email {
      display: flex;
    }

    .analyst__email-box {
      display: none;
    }
  }
}

.analyst__info-wrapper {
  grid-area: info;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--op-space-3x-small);
  flex: 1 0 0;

  @media screen and (max-width: $breakpoint-small) {
    align-items: flex-start;
  }

  .analyst__name,
  .analyst__name.link {
    font-size: var(--gc-font-small-minus-one);
    font-weight: var(--op-font-weight-bold);
    line-height: var(--op-line-height-dense);
    letter-spacing: 0.18px;
    text-transform: uppercase;

    @media screen and (max-width: $breakpoint-small) {
      font-size: var(--op-font-large);
    }
  }

  .analyst__name:not(.link) {
    color: var(--gc-link-color);
    text-decoration: underline;
    text-decoration-color: currentColor;
    text-decoration-thickness: max(1px, 0.05em);
    text-underline-offset: 1px;
    text-decoration-skip-ink: auto;
  }

  .analyst__title {
    color: var(--op-color-neutral-on-plus-max);
    font-size: var(--op-font-x-small);
    font-weight: var(--op-font-weight-semi-bold);
    line-height: var(--op-line-height-dense);
    text-align: center;

    @media screen and (max-width: $breakpoint-small) {
      font-size: var(--op-font-medium);
      text-align: left;
    }
  }

  .analyst__coverage {
    color: var(--op-color-neutral-on-plus-max);
    font-size: var(--op-font-x-small);
    font-weight: 400;
    line-height: var(--op-line-height-dense);

    @media screen and (max-width: $breakpoint-small) {
      font-size: var(--op-font-small);
    }

    .analyst__coverage-products {
      display: inline;
      font-weight: var(--op-font-weight-bold);
    }

    .analyst__coverage-industries {
      display: inline;
    }
  }
}

.analyst__photo-wrapper {
  grid-area: photo;
  --__analyst-photo-dimension: calc(var(--op-space-scale-unit) * 12); // 120px

  @media screen and (max-width: $breakpoint-x-small) {
    --__analyst-photo-dimension: calc(var(--op-space-scale-unit) * 6); // 60px
  }

  &.link-area {
    width: auto;
  }

  .analyst__photo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: var(--__analyst-photo-dimension);
    width: auto;
    height: auto;

    @media screen and (max-width: $breakpoint-x-small) {
      border-radius: var(--op-radius-circle);
    }
  }

  .analyst__photo-default {
    background: var(--op-color-neutral-plus-five);
    justify-content: center;
    stroke: var(--op-color-neutral-on-plus-four);
    max-width: var(--__analyst-photo-dimension);
    max-height: var(--__analyst-photo-dimension);
    width: 100%;
    aspect-ratio: 1;

    svg {
      max-width: var(--__analyst-photo-dimension);
      max-height: var(--__analyst-photo-dimension);
      width: 100%;
      aspect-ratio: 1;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      border-radius: var(--op-radius-circle);
    }
  }
}

.analyst__email {
  grid-area: email;
}

.analyst__email-link {
  font-size: var(--op-font-small) !important;

  @media screen and (max-width: $breakpoint-small) {
    font-size: var(--op-font-medium) !important;
  }
}

.analyst__email-box {
  grid-area: email;
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: $breakpoint-small) {
    justify-content: start;
  }

  .analyst__email-link {
    display: flex;
    height: var(--op-input-height-large);
    padding: 0px var(--op-space-medium);
    justify-content: center;
    align-items: center;
    gap: var(--op-space-x-small);
    border-radius: var(--op-radius-medium);
    box-shadow: var(--op-border-all) var(--op-color-border);
    width: 100%;
    line-height: var(--op-line-height-densest);
    max-width: calc(var(--op-space-scale-unit)* 30);
    min-width: calc(var(--op-space-scale-unit)* 23);

    .analyst__email-icon {
      text-decoration-line: none;
    }

    @media screen and (max-width: $breakpoint-small) {
      align-self: start;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      max-width: none;
    }
  }
}
