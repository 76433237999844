svg {
  .brand-color {
    path {
      fill: var(--gc-color-logo-text);
    }
  }
  .reverse-brand-color {
    path {
      fill: var(--op-color-white);
    }
  }
  .secondary-color {
    path {
      fill: var(--gc-color-secondary-original);
    }
  }
}
