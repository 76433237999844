.profile-subscriptions {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-small);

  .form-group {
    padding: 0;
  }

  .profile-subscriptions--all {
    padding-bottom: var(--op-space-small);
  }
}
