.heroku__app-body {
  --gc-color-logo-text: var(--op-color-primary-on-minus-max);

  color: var(--op-color-primary-on-minus-max);
  background: var(--gc-heroku-background);

  .link {
    color: var(--op-color-primary-on-minus-max);
  }
}

.heroku__header {
  --__gc-logo-width: 157px;

  max-width: var(--__gc-logo-width);
  margin: var(--op-space-3x-large);
}

.heroku__body {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: var(--op-space-3x-large);
}

.heroku__heading {
  text-align: center;
  font-size: var(--op-font-6x-large);
  font-weight: var(--op-font-weight-bold);
}

.heroku__heading-action {
  text-align: center;
  font-size: var(--op-font-3x-large);
  font-weight: var(--op-font-weight-normal);
  line-height: var(--op-line-height-2x-large);
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: pre-wrap;

  .heroku__heading-action-danger {
    color: var(--op-color-alerts-danger-plus-one);
    font-weight: var(--op-font-weight-medium);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--op-space-2x-small);
    white-space: normal;

    svg {
      fill: var(--op-color-alerts-danger-plus-one);
    }
  }

  .heroku__heading-action-upgrade {
    color: var(--gc-color-secondary-base);
    font-weight: var(--op-font-weight-medium);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--op-space-2x-small);
    white-space: normal;

    svg {
      fill: var(--gc-color-secondary-base);
    }
  }
}

.heroku__label {
  text-align: center;
  font-size: var(--op-font-large);
  font-weight: var(--op-font-weight-normal);
  line-height: var(--op-line-height-dense);
}
