.hubspot-updates {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-small);
}

.hubspot-update {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-3x-small);
}

.hubspot-update__attribute {
  color: var(--op-color-neutral-on-plus-max-alt);
  font-size: var(--op-font-x-small);
  font-weight: var(--op-font-weight-semi-bold);
  line-height: var(--op-line-height-base);
  text-transform: uppercase;
}

.hubspot-update__value {
  color: var(--op-color-neutral-on-plus-max);
  font-size: var(--op-font-x-small);
  line-height: var(--op-line-height-base);
  font-weight: var(--op-font-weight-semi-bold);
}

.hubspot-update__old-value {
  color: var(--op-color-neutral-on-plus-max-alt);
  font-size: var(--op-font-x-small);
  font-style: normal;
  font-weight: var(--op-font-weight-normal);
  line-height: var(--op-line-height-base);
  text-decoration-line: line-through;
}

.hubspot__text {
  color: var(--op-color-neutral-on-plus-max);
  font-size: var(--op-font-small);
  line-height: var(--op-line-height-base);
  font-weight: var(--op-font-weight-normal);
}

tr.hubspot-column-name th {
  color: var(--op-color-neutral-on-plus-max);
  font-size: var(--op-font-small);
  font-style: normal;
  font-weight: var(--op-font-weight-semi-bold);
  line-height: var(--op-line-height-base);
}

.btn--hubspot-with-icon {
  gap: var(--op-space-2x-small);
}
