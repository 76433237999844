mark {
  font-weight: var(--op-font-weight-bold);
  padding: 0;
  margin: 0;
  background: inherit;
  color: inherit
}

.search__title {
  font-weight: var(--op-font-weight-semi-bold);
  font-size: var(--op-font-x-large);
  line-height: var(--op-line-height-base);
  color: var(--op-color-neutral-on-plus-eight);
  display: flex;
  flex-direction: row;
  gap: var(--op-space-x-small);
  align-items: center;
}

sl-dropdown.search__header-dropdown .menu {
  --__gc-dropdown-min-width: 200px;
}

.search__link {
  font-size: var(--op-font-x-small);
  margin-left: var(--op-space-small);
}

.search__label {
  color: var(--op-color-neutral-on-plus-eight);
  font-size: var(--op-font-medium);
  font-weight: var(--op-font-weight-medium);
  line-height: var(--op-line-height-base);
}

.search__report-result {
  display: flex;
  gap: var(--op-space-3x-small);
  height: 39px;
  align-items: center
}

.search__result {
  --gc-separated-spacing: var(--op-space-x-small);

  padding-top: var(--op-space-x-small);
  display: flex;
  gap: var(--op-space-x-small);
  justify-content: flex-start;
  align-items: center;

  .material-symbols-outlined,
  .search__result-icon {
    color: var(--op-color-neutral-on-plus-max-alt);
  }

  .search__result-title {
    display: flex;
    align-items: center;
    gap: var(--op-space-3x-small);
    font-size: var(--gc-font-small-minus-one);
  }

  .search__result-model,
  .search__result-subtitle {
    color: var(--gc-text-meta);
    font-size: var(--gc-font-small-minus-one);
    font-weight: var(--op-font-weight-medium);
    line-height: var(--op-line-height-base);
    text-align: left;
  }
}

summary.search__accordion-summary {
  display: inline-flex;

  .search__title {
    color: var(--op-color-neutral-on-plus-max);
    font-size: var(--op-font-medium);
    font-weight: var(--op-font-weight-bold);
    line-height: var(--op-line-height-dense);
  }

  @media screen and (max-width: $breakpoint-x-small) {
    display: grid;
    width: 100%;

    .search__title {
      color: var(--op-color-neutral-on-plus-six);
      font-size: var(--op-font-medium);
    }
  }
}

.search__results-container {
  padding: 0 var(--op-space-large) 0 var(--op-space-large);
}

.search__results {
  max-height: 335px;
  overflow-y: auto;
  padding-bottom: var(--op-space-medium);
}

.search__result-icon {
  font-size: var(--op-font-x-large);
  color: var(--gc-text-meta);
}

.search-results-form {
  padding-inline: var(--op-space-large);
}

.search__results-header {
  display: flex;
  align-items: center;
  gap: var(--op-space-x-small);

  @media screen and (max-width: $breakpoint-x-small) {
    flex-direction: column;
    align-items: stretch;

    .heading {
      --_gc-heading-font-size: var(--op-font-x-large);
    }
  }
}

@media screen and (min-width: $breakpoint-x-small) {
  .search__results-container {
    @include separator;
  }
}

.filter-control {
  .trigger {
    width: 100%;
    display: flex;
    padding: 0px var(--op-space-medium);
    align-items: center;
    gap: var(--op-space-x-small);
    align-self: stretch;
    justify-content: space-between;
  }
}

.filter-control__trigger {
  @media screen and (width > $breakpoint-x-small) {
    --__op-btn-height: var(--_op-btn-height-small);
    --__op-btn-font-size: var(--_op-btn-font-small);
    --__op-btn-padding: var(--_op-btn-padding-small);
    max-width: 100%;
  }
}
