%card-global {
  border-radius: var(--gc-radius-default);
  background-color: var(--op-color-neutral-plus-max);
  color: var(--op-color-neutral-on-plus-max);

  .card__header,
  .card__body,
  .card__footer {
    padding: var(--op-space-x-large);

    @media screen and (max-width: $breakpoint-small) {
      padding: 0px;
    }
  }
}

.card__header {
  display: flex;
}

.card {
  @media screen and (max-width: $breakpoint-small) {
    padding: var(--op-space-medium);
    width: 100%;
  }
}

.card-padded {
  padding: var(--op-space-x-large)
}

.card-login {
  @extend %card-global;

  @media screen and (max-width: $breakpoint-x-small) {
    padding: 0;
    box-shadow: none;
    border-radius: 0;
  }

  & {
    width: 100%;
    max-width: calc(var(--op-size-unit) * 100); //400px
  }

  .mobile-app & {
    max-width: unset;
  }

  .card__header,
  .card__body,
  .card__footer {
    @media screen and (max-width: $breakpoint-small) {
      padding: var(--op-space-x-large);
    }
  }

  .card__body {
    display: flex;
    flex-direction: column;
    gap: var(--op-space-medium);

    .form-group {
      padding: 0;
    }
  }

  .card__header {
    display: flex;
    padding: var(--op-space-3x-large) var(--op-space-x-large) var(--op-space-2x-large) var(--op-space-x-large);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--op-space-x-large);
    align-self: stretch;
    background: var(--gc-color-navbar-background);
    color: var(--gc-color-navbar-text);

    .card__header--title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      font-size: var(--op-font-2x-large);
      font-weight: var(--op-font-weight-bold);
      line-height: var(--op-line-height-dense);
    }
  }

  .card__footer {
    .actions {
      display: flex;
      justify-content: space-between;
      gap: var(--op-space-small);
      align-items: center;

      @media screen and (max-width: $breakpoint-x-small) {
        flex-direction: column-reverse;
        align-items: stretch;
      }
    }
  }
}

.card-content-preview,
.card-report {
  @extend %card-global;

  .card__header {
    padding-block-end: 0;

    @media screen and (max-width: $breakpoint-small) {
      padding: var(--op-space-large) var(--op-space-large) 0 var(--op-space-large);
    }
  }

  .card__header + .card__body {
    padding-block-start: var(--op-space-small);
  }

  .card__body {
    display: flex;
    flex-direction: column;
    gap: var(--op-space-small);

    @media screen and (max-width: $breakpoint-small) {
      padding: var(--op-space-large);
    }
  }
}

.card-error {
  @extend %card-global;

  align-self: center;
  max-width: calc(var(--op-size-unit) * 150);
  width: 100%;

  .card__header {
    box-shadow: var(--op-border-bottom) var(--op-border-color);
  }

  @media screen and (max-width: $breakpoint-small) {
    &.card--shadow-large {
      box-shadow: none;
    }

    .card__header,
    .card__body,
    .card__footer {
      padding: var(--op-space-2x-large) var(--op-space-large);
    }
  }
}
