.products {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-3x-large);
}

.product {
  padding-bottom: var(--op-space-3x-large);
  box-shadow: var(--op-border-bottom) var(--op-color-border);
  display: flex;

  @media screen and (max-width: $breakpoint-small) {
    flex-direction: column;
  }

    &__banner {
      background: var(--gc-color-navbar-background);
      border-left: 4px solid var(--gc-color-secondary-original);
      color: var(--op-color-primary-plus-max);
      font-size: var(--op-font-small);
      font-weight: 775;
      padding: 2px var(--op-space-2x-large) 2px var(--op-space-small);

      // Force rendering on print mode
      -webkit-print-color-adjust: exact;   /* Chrome, Safari 6 – 15.3, Edge */
      color-adjust: exact;                 /* Firefox 48 – 96 */
      print-color-adjust: exact;           /* Firefox 97+, Safari 15.4+ */
    }

  &__select.form-control {
    color: var(--gc-link-color);
    font-weight: 500;

    form:has(&) {
      @media screen and (max-width: $breakpoint-x-small) {
        width: 100%;
      }
    }
  }

  .product__image {
    --gc-color-on-background: var(--op-color-primary-on-minus-five);
    --gc-color-background: var(--op-color-primary-minus-five);
    background-color: var(--gc-color-background);
    color: var(--gc-color-on-background);
    border-radius: var(--gc-radius-default);
    font-family: var(--op-font-family);
    padding: var(--op-space-3x-large);
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: start;
    overflow: hidden;
    flex: 0 0 40%;

    @media screen and (max-width: $breakpoint-small) {
      flex-basis: auto;
      padding: var(--op-space-x-large);
      width: 100%;
    }

    .product__code {
      --gc-product-code-outline-width: calc(var(--op-size-unit) / 2);
      font-size: calc(90 * var(--op-size-unit)); // 360px
      letter-spacing: -0.04em;
      line-height: calc(90 * var(--op-size-unit)); // 360px
      color: var(--gc-color-background);
      margin-block-start: -0.45em;
      position: relative;
      left: -0.075em;
      text-shadow:
        calc(var(--gc-product-code-outline-width) * -1) calc(var(--gc-product-code-outline-width) * -1) 0 var(--gc-color-on-background),
        var(--gc-product-code-outline-width) calc(var(--gc-product-code-outline-width) * -1) 0 var(--gc-color-on-background),
        calc(var(--gc-product-code-outline-width) * -1) var(--gc-product-code-outline-width) 0 var(--gc-color-on-background),
        var(--gc-product-code-outline-width) var(--gc-product-code-outline-width) 0 var(--gc-color-on-background);
    }

    .product__text {
      font-size: var(--op-font-3x-large);
      font-weight: var(--op-font-weight-normal);
      line-height: var(--op-line-height-denser);

      .product__name {
        color: var(--op-color-white);
        font-weight: var(--op-font-weight-semi-bold);
      }
    }

  }

  &:nth-of-type(2) .product__image {
    --gc-color-on-background: var(--op-color-primary-on-base-alt);
    --gc-color-background: var(--op-color-primary-base);
  }

  &:nth-of-type(3) .product__image {
    --gc-color-on-background: var(--op-color-primary-on-plus-two-alt);
    --gc-color-background: var(--op-color-primary-plus-two);
  }

  &:nth-of-type(4) .product__image {
    --gc-color-on-background: var(--op-color-primary-on-plus-four-alt);
    --gc-color-background: var(--op-color-primary-plus-four);
    flex-direction: column-reverse;

    .product__code {
      margin-block-start: -0.1em;
      margin-block-end: -0.40em;
    }
  }

  &:nth-of-type(5) .product__image {
    --gc-color-on-background: var(--op-color-primary-on-minus-seven-alt);
    --gc-color-background: var(--op-color-primary-minus-seven);
    flex-direction: column-reverse;

    .product__code {
      margin-block-start: -0.1em;
      margin-block-end: -0.40em;
    }
  }

  ul {
    padding-left: var(--op-space-2x-large)
  }
}
