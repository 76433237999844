.credit-score--legend-container {
  --_legend-container-spacing: var(--op-space-2x-small);

  display: flex;
  padding: var(--op-space-3x-small) var(--op-space-small);
  justify-content: center;
  align-items: center;
  align-self: self-start;
  gap: var(--_legend-container-spacing);
  border-radius: var(--op-radius-medium);
  box-shadow: var(--op-border-all) var(--op-color-neutral-plus-five);

  .credit-score--legend {
    color: var(--gc-text-default);
    font-size: var(--op-font-x-small);
    font-weight: var(--op-font-weight-medium);
    line-height: var(--op-line-height-base);
  }


  > :not(:last-child) {
    content: "";
    border-right: 1px solid var(--op-color-neutral-plus-five);
    padding-right: var(--_legend-container-spacing);
  }

  @media screen and (max-width: $breakpoint-x-small) {
    display: none;
  }
}

table.table.company-table.company-table--credit-score {
  .company-table__credit-score-desktop {
    font-feature-settings: "liga" 1, "calt" 1, "tnum";
    text-align: right;
  }
  .company-table__credit-score-mobile {
    display: none;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    tr {
      gap: var(--op-space-x-small);
    }

    .company-table__credit-score {
      --__table-disintegrate-column-order: 2;
      width: 100%;

      .company-table__credit-score-desktop {
        display: none;
      }

      .company-table__credit-score-mobile {
        display: block;
      }
    }

    .company-table__sector {
      --__table-disintegrate-column-order: 3;
    }

    .company-table__industry {
      --__table-disintegrate-column-order: 4;
    }
  }
}
