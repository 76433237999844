table.table.data-sheet{
  --__op-table-cell-height: 1rem;
  border: none;
  line-height: var(--op-line-height-denser);
  table-layout: fixed;

  thead tr th {
    height: 0px;
    padding: 0px;
  }

  tbody tr {
    box-shadow: none;

    td {
      box-shadow: none;
      font-size: var(--op-font-2x-small);
      padding: 0px var(--op-space-2x-small) 0px 0px;

      white-space: nowrap;
      max-width: 0;
    }
  }

  & * {
    @media print {
      line-height: var(--op-line-height-denser);
    }
  }
}

.data-sheet {
  padding-left: var(--op-space-x-large);
  padding-right: var(--op-space-x-large);

  &__information {
    display: block;
    width: calc(var(--op-breakpoint-small) - calc(var(--op-space-large) * 2));
    white-space: normal;
  }

  &__border {
    &-bottom {
      border-bottom: var(--op-border-width) solid var(--op-color-border)
    }
  }

  &__bold {
    font-weight: var(--op-font-weight-extra-bold);
  }

  &__italic {
    font-style: italic;
  }

  &__underline {
    text-decoration: underline;
  }

  &__strikethrough {
    text-decoration: line-through;
  }
}

.data-sheet-preview__pdf {
  width: 100%;
  height: auto;
}

.data-sheet-preview__controls {
  position: absolute;
  top: 50%;
  display: flex;
  flex-direction: column;
  gap: var(--op-space-medium);
  padding-left: var(--op-space-medium);
}

.height-small {
  height: var(--op-space-small);
}

.height-medium {
  height: var(--op-space-medium);
}

.height-large {
  height: var(--op-space-large);
}

.height-x-large {
  height: var(--op-space-x-large);
}

.height-2x-large {
  height: var(--op-space-2x-large);
}
