.distribution-preview {
  border: solid var(--op-border-width) var(--op-color-border);

  .text-content {
    white-space: pre-wrap;
  }
}

.distribution__logo {
  height: calc(var(--op-size-unit) * 10); //40px
}

.badge-wrapper {
  .badge-upgrade-downgrade {
    background-color: var(--op-color-primary-minus-seven);
    color: var(--op-color-primary-on-minus-seven);
  }

  .badge-comments {
    background-color: var(--op-color-primary-plus-four);
    color: var(--op-color-primary-on-plus-four);
  }

  .badge-product {
    background-color: var(--op-color-alerts-info-base);
    color: var(--op-color-alerts-info-on-base);

    &.investment-grade {
      background-color: var(--op-color-primary-minus-five);
      color: var(--op-color-primary-on-minus-five);
    }

    &.high-yield {
      background-color: var(--op-color-primary-base);
      color: var(--op-color-primary-on-base);
    }

    &.emerging-markets {
      background-color: var(--op-color-primary-plus-two);
      color: var(--op-color-primary-on-plus-two);
    }
  }
}

tr.unsaved {
  color: var(--op-color-alerts-warning-base);
}
